import { request } from "../../app/axios";

export const fetchIngredients = async () => {
	let data = await request("extracts");
	data = data.items.filter((d) => d);
	return data;
};

export const fetchExtracts = async () => {
	return new Promise((resolve) =>
		setTimeout(
			() =>
				resolve({
					data: [
						{
							img: "https://picsum.photos/300/200",
							name: "Extract 1",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod laoreet.",
						},
						{
							img: "https://picsum.photos/300/200",
							name: "Extract 2",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod laoreet.",
						},
						{
							img: "https://picsum.photos/300/200",
							name: "Extact 3",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod laoreet.",
						},
					],
				}),
			500
		)
	);
};

export const fetchVolumes = async () => {
	return new Promise((resolve) =>
		setTimeout(
			() =>
				resolve({
					data: [
						{
							value: 30,
							label: 30,
						},
						{
							value: 40,
							label: 40,
						},
						{
							value: 50,
							label: 50,
						},
						{
							value: 60,
							label: 60,
						},
						{
							value: 70,
							label: 70,
						},
						{
							value: 80,
							label: 80,
						},
						{
							value: 90,
							label: 90,
						},
					],
				}),
			500
		)
	);
};
