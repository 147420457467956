import { useHistory } from "react-router";

const useCurrentPath = () => {
	const history = useHistory();
	const currentPath = history.location.pathname.includes("/recipe-designer/")
		? "/recipe-designer/"
		: "/recipe-suggestions/";
	const url = history.location.pathname;
	const formattedName = history.location.pathname.includes("/recipe-designer/")
		? "Designer"
		: "Suggestions";
	return [currentPath, url, formattedName];
};

export default useCurrentPath;
