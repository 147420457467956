import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowSaveRecipe, toggleLoginForm } from "../../redux/content";
import { selectIsSignedIn } from "../../redux/user";

const Button = styled.button`
	padding-left: 2rem;
	padding-right: 2rem;
	margin-bottom: 2rem;
`;

const SaveRecipeButton = () => {
	const dispatch = useDispatch();
	const isSignedIn = useSelector(selectIsSignedIn);

	return (
		<>
			<Button
				className="btn btn-secondary "
				onClick={() => {
					isSignedIn
						? dispatch(toggleShowSaveRecipe())
						: dispatch(toggleLoginForm());
				}}
			>
				{isSignedIn ? "Save Recipe" : "Login or sign up to save recipe"}
			</Button>
		</>
	);
};

export default SaveRecipeButton;
