import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchABV } from "./abvAPI";

const initialState = {
	ABV: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchABVAsync = createAsyncThunk("abv/fetchABV", async () => {
	const response = await fetchABV();
	// The value we return becomes the `fulfilled` action payload
	return response.data;
});

export const abvSlice = createSlice({
	name: "abv",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(fetchABVAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchABVAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.ABV = action.payload;
			});
	},
});

export const selectABVAmounts = (state) => state.abv.ABV;

export default abvSlice.reducer;
