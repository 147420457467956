import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSuggestions } from "./suggestionsAPI";

const initialState = {
	suggestions: [],
};

export const fetchSuggestionsAsync = createAsyncThunk(
	"suggestions/fetchSuggestions",
	async () => {
		const response = await fetchSuggestions();

		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const suggestionsSlice = createSlice({
	name: "suggestions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSuggestionsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchSuggestionsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.suggestions = action.payload;
			})
			.addCase(fetchSuggestionsAsync.rejected, (state, action) => {
				console.warn(action);
			});
	},
});

export const selectSuggestions = (state) => state.suggestions.suggestions;

export default suggestionsSlice.reducer;
