import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	fetchExtracts,
	fetchVolumes,
	fetchIngredients,
} from "./ingredientsAPI";

const initialState = {
	extracts: [],
	volumes: [],
	ingredients: [],
	selectedIngredient: {},
};

export const fetchIngredientsAsync = createAsyncThunk(
	"ingredients/fetchIngredients",
	async () => {
		const response = await fetchIngredients();
		console.log(response);
		return response;
	}
);

export const fetchExtractsAsync = createAsyncThunk(
	"ingredients/fetchExtracts",
	async () => {
		const response = await fetchExtracts();
		return response.data;
	}
);

export const fetchVolumesAsync = createAsyncThunk(
	"ingredients/fetchVolumes",
	async () => {
		const response = await fetchVolumes();
		return response.data;
	}
);

export const ingredientsSlice = createSlice({
	name: "ingredients",
	initialState,
	reducers: {
		setSelectedIngredient(state, { payload: id }) {
			const ingredient = state.ingredients.find(
				(ingredient) => ingredient.id === id
			);

			state.selectedIngredient = ingredient || {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchExtractsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchExtractsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.extracts = action.payload;
			})
			.addCase(fetchVolumesAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchVolumesAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.volumes = action.payload;
			})
			.addCase(fetchIngredientsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchIngredientsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.ingredients = action.payload;
			})
			.addCase(fetchIngredientsAsync.rejected, (state, action) => {
				state.status = "failed";
				console.warn(action.error);
			});
	},
});

export const { setSelectedIngredient } = ingredientsSlice.actions;

export const selectExtracts = (state) => state.ingredients.extracts;
export const selectVolumes = (state) => state.ingredients.volumes;
export const selectIngredients = (state) => state.ingredients.ingredients;
export const selectSelectedIngredient = (state) =>
	state.ingredients.selectedIngredient;

export default ingredientsSlice.reducer;
