import { request } from "../../app/axios";

export const fetchFAQ = async () => {
	let data = await request("faq");
	return data;
};

export const fetchLandingPageContent = async () => {
	let data = await request("home");
	return data;
};

export const fetchActionTextContent = async () => {
	let data = await request("actiontext");
	return data;
};
