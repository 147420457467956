import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		fontFamily: ` "Raleway", sans-serif !important`,
		color: "#40102A",
		fontSize: "16px",
	},
	accordion: {
		borderBottom: "2px solid #40102A",
	},
	heading: {
		fontSize: "18px",

		fontWeight: "700",
	},

	icon: {
		border: "3px solid #40102A",
		borderRadius: "50%",
		height: "40px",
		width: "40px",
		fill: "#40102A",
	},
}));

const FAQAccordion = ({ faqs }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{faqs &&
				faqs.map(({ faqHeader: question, faqBody: answer }) => (
					<Accordion className={classes.accordion}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className={classes.icon} />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<h4 className={classes.heading}>{question}</h4>
						</AccordionSummary>
						<AccordionDetails>
							<p>{answer}</p>
						</AccordionDetails>
					</Accordion>
				))}
		</div>
	);
};

export default FAQAccordion;
