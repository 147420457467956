import React from "react";
import styled from "styled-components";
import CreateButton from "../common/CreateButton";
import RecipeCards from "../beerImage/RecipeCards";
import { useSelector } from "react-redux";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import { selectActionText } from "../../redux/content";

const VariableText = styled.div`
	margin-bottom: 0;
	font-size: 0.9rem;
`;

const BottomNav = ({ mobile }) => {
	const recipeItems = useSelector(builtRecipeSelectors.recipe);
	const recipeItemsCheck = recipeItems.some((item) => item);
	const actionText = useSelector(selectActionText);
	return mobile ? (
		<footer className={` py-3 px-0 d-block d-xl-none`}>
			<div className="container-xxl">
				<div className="row align-items-center">
					{recipeItemsCheck && (
						<div className="col-12 d-none justify-content-center align-items-center">
							<VariableText>{actionText.variables}</VariableText>
							<span className="edit-icon ms-3">
								<i className="fas fa-pencil-alt"></i>
							</span>
						</div>
					)}
					<RecipeCards />
					<CreateButton mobile />
				</div>
			</div>
		</footer>
	) : (
		<footer className={`footer-nav--dt pb-4  d-none d-xl-block`}>
			<div className="row d-flex align-items-center ">
				{recipeItemsCheck && (
					<div className="col-12 d-none justify-content-center align-items-center">
						<VariableText>{actionText.variables}</VariableText>
						<span className="edit-icon ms-3">
							<i className="fas fa-pencil-alt"></i>
						</span>
					</div>
				)}
				<RecipeCards showButton />
			</div>
		</footer>
	);
};

export default BottomNav;
