import React from "react";
import { useSelector } from "react-redux";
import { selectInstructions } from "../../../redux/builtRecipe";

const Instructions = () => {
	const recipe = useSelector(selectInstructions);
	let instructions;
	if (recipe) {
		instructions = recipe.instructions;
	}
	return (
		<div className="row instruction-selection justify-content-center">
			<div className="col-12 mb-4">
				<h2>
					Your brew, a{" "}
					{instructions &&
						instructions["1"] &&
						Object.keys(instructions).length}{" "}
					step guide...
				</h2>
				<h4>
					Sanitise your equipment and make sure you have all of the ingredients
					required for your recipe.
				</h4>
			</div>

			<div className="recipe-option-area instruction-selection--method">
				<div className="row">
					{instructions &&
						Object.keys(instructions).map((key, i) => (
							<div key={i} className="col-12">
								<h4 className="mb-2">Step {i + 1}</h4>
								<p>{instructions[key]}</p>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Instructions;
