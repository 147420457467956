import React from "react";
// import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";

import { StyledLabel, Label, StyledTextField } from "./LoginInputs";

import styled from "styled-components";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
	selectActionText,
	toggleLoginForm,
	toggleShowPasswordReset,
} from "../../redux/content";
import { toast } from "react-toastify";
import Toast from "../toast/Toast";

const SignUpSpan = styled.span`
	text-decoration: underline;
	text-transform: uppercase;
	cursor: pointer;
	margin-left: 1rem;
	font-weight: bold;
`;

const validationSchema = yup.object({
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
});

const LoginForm = () => {
	const dispatch = useDispatch();
	// const [showSkip, setShowSkip] = useState(true);
	// const location = useLocation();
	// useEffect(() => {
	// 	if (location.pathname === "/recipe-suggestions/ingredients")
	// 		setShowSkip(false);
	// }, [location.pathname]);

	//const [showPassword, setShowPassword] = useState(false);
	//const handleClickShowPassword = () => setShowPassword(!showPassword);
	// const handleMouseDownPassword = () => setShowPassword(!showPassword);
	const text = useSelector(selectActionText);
	//const history = useHistory();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			toast(<Toast messageKey="forgottenPassword" />);
			dispatch(toggleLoginForm());
			dispatch(toggleShowPasswordReset());
		},
	});

	const date = new Date();
	date.setFullYear(date.getFullYear() - 18);

	return (
		<div className="container-lg" data-testid="login-modal">
			<div className="row">
				<form onSubmit={formik.handleSubmit}>
					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ms-4">
								{" "}
								<h1>{text.form}</h1>
							</div>
						</div>
					)}

					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ms-4">
								{" "}
								<h3>Forgotten Password</h3>
							</div>
						</div>
					)}

					<div
						className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group"
						data-testid="login-email"
					>
						<StyledLabel
							control={
								<StyledTextField
									id="email"
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your email here...."
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Enter your Email address</Label>}
							labelPlacement="top"
						/>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-4 mt-5">
						<button type="submit" className="btn btn-secondary w-100">
							Reset Password
						</button>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-5">
						<p className="text-center w-100">
							<SignUpSpan
								onClick={() => {
									dispatch(toggleShowPasswordReset());
								}}
							>
								&lt; Back
							</SignUpSpan>
						</p>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
