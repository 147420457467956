import { request } from "../../app/axios";
import { baseUmbracoUrl } from "../../app/axios";

export const fetchSuggestions = async () => {
	let data = await request("recipes");
	data = data.items.filter((d) => d);
	data = data.map(({ images = "", ...item }) => ({
		...item,

		img: baseUmbracoUrl + ((images && images[0]) || ""),
	}));
	return data;
};

export const fetchSuggestedRecipe = async (id) => {
	let data = await request(`recipes/${id}`);

	return data;
};
