import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import styled from "styled-components";
import useCurrentPath from "./useCurrentPath";

const DTButtonContainer = styled.div`
	width: 32%;
	margin-left: 0.5rem;
	display: flex;
	align-items: start;
`;

const CreateButton = ({ mobile }) => {
	const selectedABV = useSelector(builtRecipeSelectors.abv);
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const selectedAroma = useSelector(builtRecipeSelectors.aroma);
	const selectedFlavour = useSelector(builtRecipeSelectors.flavour);
	const selections = [
		selectedABV,
		selectedStyle,
		selectedFlavour,
		selectedAroma,
	];

	const showMobile = selections.every((selection) => selection) && mobile;

	const showDesktop = selections.every((selection) => selection) && !mobile;
	const [currentPath, , formattedName] = useCurrentPath();

	const buttonText =
		formattedName === "Designer" ? "Create Recipe +" : "Update Recipe +";
	return (
		<>
			{showMobile && (
				<div className=" col-12 justify-content-center py-4  d-block d-xl-none">
					<div className="col-12">
						<Link
							to={`${currentPath}ingredients`}
							className="btn btn-secondary w-100"
						>
							{buttonText}
						</Link>
					</div>
				</div>
			)}
			{showDesktop && (
				<DTButtonContainer>
					<Link
						to={`${currentPath}ingredients`}
						className="btn btn-secondary w-100"
					>
						{buttonText}
					</Link>
				</DTButtonContainer>
			)}
		</>
	);
};

export default CreateButton;
