import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchStyles } from "./styleAPI";

const initialState = {
	styles: [],
};

export const fetchStylesAsync = createAsyncThunk(
	"style/fetchStyles",
	async () => {
		const response = await fetchStyles();

		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const styleSlice = createSlice({
	name: "style",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStylesAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchStylesAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.styles = action.payload;
			})
			.addCase(fetchStylesAsync.rejected, (state, action) => {
				console.warn(action);
			});
	},
});

export const selectStyles = (state) => state.style.styles;
export const selectFilteredColours = (state) =>
	state.style.styles
		.filter((style) => state.builtRecipe.selectedStyle.label === style.label)
		.map((style) => style.colours)[0];
export const selectFilteredYeasts = (state) =>
	state.style.styles
		.filter((style) => state.builtRecipe.selectedStyle.label === style.label)
		.map((style) => style.yeasts)[0];
export const selectFilteredABVS = (state) =>
	state.style.styles
		.filter((style) => state.builtRecipe.selectedStyle.label === style.label)
		.map((style) => style.abvs)[0];
export const selectFilteredFlavours = (state) =>
	state.style.styles
		.filter((style) => state.builtRecipe.selectedStyle.label === style.label)
		.map((style) => style.flavours)[0];

export default styleSlice.reducer;
