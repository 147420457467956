import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAromas } from "./aromaAPI";

const initialState = {
	aromas: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchAromasAsync = createAsyncThunk(
	"aroma/fetchAromas",
	async () => {
		const response = await fetchAromas();
		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const aromaSlice = createSlice({
	name: "aroma",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(fetchAromasAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchAromasAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.aromas = action.payload;
			});
	},
});

export const selectAromas = (state) => state.aroma.aromas;
export const selectFilteredHops = (state) =>
	state.aroma.aromas
		.filter((aroma) => state.builtRecipe.selectedAroma.label === aroma.label)
		.map((aroma) => aroma.hops)[0];

export default aromaSlice.reducer;
