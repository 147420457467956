import React from "react";
import { useSelector } from "react-redux";
import { selectInstructions } from "../../../redux/builtRecipe";

const Equipment = () => {
	const instructions = useSelector(selectInstructions);

	return (
		<div className="row instruction-selection justify-content-center">
			<div className="col-12 mb-4">
				<h2>Your Equipment</h2>
			</div>

			<div className="recipe-option-area row">
				{instructions &&
					instructions.equipment &&
					instructions.equipment.map((item, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-8 ps-0">
								<div className="row">
									<div className="col-8">
										<h4>{item.label}</h4>
									</div>
									{item.description && (
										<div className="col-12 mt-3">
											<p>{item.description}</p>
										</div>
									)}
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Equipment;
