import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetSelections } from "../../redux/builtRecipe";
import { Switch, Route, useLocation } from "react-router-dom";

import ABV from "./ABV";
import Aroma from "./Aroma";
import Colour from "./Colour";
import Flavour from "./Flavour";
import Recipe from "./Recipe";
import Style from "./Style";
import Confirmation from "../Confirmation";
import Ingredients from "./Ingredients";
import Form from "../Form";

const RecipeDesigner = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		if (location.pathname === "/") dispatch(resetSelections());
	}, [dispatch, location]);
	return (
		<>
			<Switch>
				<Route exact path="/recipe-designer/style">
					<Style />
				</Route>
				<Route exact path="/recipe-designer/flavour">
					<Flavour />
				</Route>
				<Route exact path="/recipe-designer/abv">
					<ABV />
				</Route>
				<Route exact path="/recipe-designer/aroma">
					<Aroma />
				</Route>
				<Route exact path="/recipe-designer/colour">
					<Colour />
				</Route>

				<Route exact path="/recipe-designer/recipe">
					<Recipe />
				</Route>
				<Route exact path="/recipe-designer/overview">
					<Confirmation />
				</Route>
				<Route exact path="/recipe-designer/ingredients">
					<Ingredients />
				</Route>
				<Route exact path="/recipe-designer/user-details">
					<Form />
				</Route>
			</Switch>
		</>
	);
};

export default RecipeDesigner;

export { ABV, Aroma, Colour, Flavour, Recipe, Style };
