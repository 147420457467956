import React, { useEffect } from "react";
import { fetchFAQAsync, selectFAQ } from "../redux/content";
import { useDispatch, useSelector } from "react-redux";
import FAQAccordion from "../components/accordion";
import styled from "styled-components";

const SectionHeader = styled.h3`
	font-size: 1.25rem;
	padding-left: 1rem;
`;

const FAQ = () => {
	const FAQs = useSelector(selectFAQ);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchFAQAsync());
	}, [dispatch]);
	return (
		<main className="container-xxl">
			<div className="text-center pt-5">
				<h2 className="mb-3">{FAQs && FAQs.title}</h2>
			</div>
			{FAQs &&
				FAQs.sections &&
				FAQs.sections.map((section) => (
					<>
						<SectionHeader className="my-3">{section.title}</SectionHeader>
						<FAQAccordion faqs={section.questions} />
					</>
				))}
		</main>
	);
};

export default FAQ;
