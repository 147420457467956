import { request } from "../../app/axios";
import { baseUmbracoUrl } from "../../app/axios";

export const fetchFlavours = async () => {
	let data = await request("base-flavours");
	data = data.items.filter((d) => d);
	data = data.map(({ images = "", extracts, ...item }) => ({
		...item,
		extracts:
			extracts &&
			extracts.map(({ images = "", ...extract }) => ({
				...extract,
				img: baseUmbracoUrl + ((images && images[0]) || ""),
			})),
		img: baseUmbracoUrl + ((images && images[0]) || ""),
	}));
	return data;
};
