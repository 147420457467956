import React, { useEffect, useMemo } from "react";
import { builtRecipeSelectors, setShowReview } from "../../redux/builtRecipe";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Tabs from "../../components/tabs";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
	fetchSavedRecipeAsync,
	resetSelections,
	setIsLoading,
	selectIsLoading,
} from "../../redux/builtRecipe";
import { fetchStylesAsync } from "../../redux/style";

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

const Loader = styled.div`
	border-radius: 50%;
	padding-top: 20vh;
	margin: 1rem;
	display: grid;
	place-items: center;
`;

const Recipe = () => {
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const isLoading = useSelector(selectIsLoading);
	let query = useQuery();
	const dispatch = useDispatch();
	useEffect(() => {
		window.scrollTo(0, 0);
		const recipeId = query.get("recipe_id");
		const isSocialShare = query.get("social_share");

		if (recipeId) {
			(async () => {
				dispatch(resetSelections());
				dispatch(setIsLoading(true));
				await dispatch(fetchStylesAsync());
				await dispatch(fetchSavedRecipeAsync(recipeId));
				if (isSocialShare) {
					dispatch(setShowReview(false));
				}
				dispatch(setIsLoading(false));
			})();
		}
	}, [dispatch, query]);

	return (
		<main className="container-xxl">
			{isLoading && (
				<Loader>
					<CircularProgress style={{ color: "#d55d35" }} />
				</Loader>
			)}
			{!isLoading && (
				<>
					<div className="row mb-2">
						<div className="col-md-12 text-center text-intro py-3">
							<h1>
								Here’s your <strong>{selectedStyle.label}</strong> recipe
							</h1>
						</div>
					</div>

					<Tabs />
				</>
			)}
		</main>
	);
};

export default Recipe;
