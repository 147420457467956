import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	fetchLandingPageContent,
	fetchFAQ,
	fetchActionTextContent,
} from "./contentAPI";

const initialState = {
	landingPage: {},
	FAQ: {},
	isModalOpen: false,
	showLoginForm: false,
	showPasswordReset: false,
	showSaveRecipe: false,
	showSignUpForm: false,
	errorMessage: "",
};

export const fetchLandingPageContentAsync = createAsyncThunk(
	"content/fetchLandingPage",
	async () => {
		const response = await fetchLandingPageContent();
		return response;
	}
);

export const fetchFAQAsync = createAsyncThunk("content/fetchFAQ", async () => {
	const response = await fetchFAQ();
	return response;
});

export const fetchActionTextAsync = createAsyncThunk(
	"content/fetchActionText",
	async () => {
		const response = await fetchActionTextContent();
		return response;
	}
);

export const contentSlice = createSlice({
	name: "content",
	initialState,
	reducers: {
		toggleLoginForm: {
			reducer(state, action) {
				return { ...state, ...action.payload, isModalOpen: !state.isModalOpen };
			},
			prepare() {
				return {
					payload: {
						showPasswordReset: false,
						showSaveRecipe: false,
						showLoginForm: true,
						showSignUpForm: false,
					},
				};
			},
		},
		toggleShowPasswordReset(state) {
			state.showLoginForm = !state.showLoginForm;
			state.showPasswordReset = !state.showPasswordReset;
		},
		toggleShowSaveRecipe(state) {
			state.showPasswordReset = false;
			state.showLoginForm = false;
			state.isModalOpen = !state.isModalOpen;
			state.showSaveRecipe = true;
			return state;
		},
		toggleShowSignUpForm(state, { payload }) {
			state.showLoginForm = false;
			state.showSignUpForm = payload;
			state.isModalOpen = payload;
		},
		setErrorMessage(state, action) {
			state.errorMessage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLandingPageContentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchLandingPageContentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.landingPage = action.payload;
			})
			.addCase(fetchFAQAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchFAQAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.FAQ = action.payload;
			})
			.addCase(fetchActionTextAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchActionTextAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.actionText = action.payload;
			});
	},
});

export const {
	toggleLoginForm,
	toggleShowPasswordReset,
	toggleShowSaveRecipe,
	toggleShowSignUpForm,
	setErrorMessage,
} = contentSlice.actions;

export const selectLandingPageContent = (state) => state.content.landingPage;
export const selectFAQ = (state) => state.content.FAQ;
export const selectActionText = (state) => state.content.actionText;
export const isModalOpenSelector = (state) => state.content.isModalOpen;
export const isShowLoginForm = (state) => state.content.showLoginForm;
export const isShowPasswordReset = (state) => state.content.showPasswordReset;
export const isShowSaveRecipe = (state) => state.content.showSaveRecipe;
export const isShowSignUpForm = (state) => state.content.showSignUpForm;
export const selectErrorMessage = (state) => state.content.errorMessage;

export default contentSlice.reducer;
