import React from "react";
import { builtRecipeSelectors } from "../../../redux/builtRecipe";
import { useSelector } from "react-redux";
import Cards from "../../cards/Cards";
import { useLocation } from "react-router-dom";

const Overview = () => {
	let selectedRecipe = useSelector(builtRecipeSelectors.recipe);
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const location = useLocation();
	if (location.pathname.includes("recipe-suggestions")) {
		selectedRecipe = selectedRecipe.map((ingredient) => ({
			...ingredient,
			to: `/recipe-suggestions/${ingredient.type}`,
		}));
	}

	return (
		<>
			<div className="row justify-content-center py-4 mb-5 d-flex d-xl-none">
				<div className="col-6 col-sm-4">
					<img
						alt="alt text"
						className="img-fluid w-100"
						src={selectedStyle.img || ""}
					/>
				</div>
			</div>

			<div className="row  py-4 mb-5 d-none d-xl-flex">
				<div className="col-3 offset-1">
					<img
						alt="alt text"
						className="img-fluid w-100"
						src={selectedStyle.img || ""}
					/>
				</div>

				<Cards
					isConfirmationPage
					isRecipePage
					list={selectedRecipe}
					showDescription
				/>
			</div>

			<Cards
				isConfirmationPage
				mobile
				isRecipePage
				list={selectedRecipe}
				showDescription
			/>
		</>
	);
};

export default Overview;
