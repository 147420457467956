import React from "react";
import { useSelector } from "react-redux";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import { selectActionText } from "../../redux/content";
import useActionTextFormat from "./useActionTextFormat";

const ActionText = ({ page, mobile, showBoth }) => {
	const { label } = useSelector(builtRecipeSelectors.style);
	const actionText = useSelector(selectActionText);
	const formattedText = useActionTextFormat(actionText, label);

	return showBoth ? (
		<div className={`row d-block`}>
			<div className="col-10 offset-1 col-lg-8 offset-lg-2 text-center text-intro pt-3">
				<h1>{formattedText[page]}</h1>
			</div>
		</div>
	) : (
		<div className={`row ${mobile ? "d-none" : "d-none d-xl-block"}`}>
			<div className="col-10 offset-1 col-lg-8 offset-lg-2 text-center text-intro pt-3">
				<h1>{formattedText[page]}</h1>
			</div>
		</div>
	);
};

export default ActionText;
