import React from "react";
import { useSelector } from "react-redux";
import { selectInstructions } from "../../redux/builtRecipe";

const AmountOfPints = () => {
	const instructions = useSelector(selectInstructions);

	return (
		<div>
			<h5>
				This recipe will make approximately {instructions.numberOfPints} pints
			</h5>
		</div>
	);
};

export default AmountOfPints;
