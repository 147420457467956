import { request, getSavedRecipes, deleteSavedRecipe } from "../../app/axios";

export const fetchFAQ = async () => {
	let data = await request("faq");
	return data;
};

export const fetchLandingPageContent = async () => {
	let data = await request("home");
	return data;
};

export const fetchActionTextContent = async () => {
	let data = await request("actiontext");
	return data;
};

export const fetchSavedRecipes = async () => {
	let data = await getSavedRecipes();
	return data;
};

export const deleteRecipe = async (id) => {
	let data = await deleteSavedRecipe(id);
	return data;
};
