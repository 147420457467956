import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	fetchLandingPageContent,
	fetchFAQ,
	fetchActionTextContent,
	fetchSavedRecipes,
	deleteRecipe,
} from "./userAPI";
import { toast } from "react-toastify";
import Toast from "../../components/toast/Toast";

const initialState = {
	isSignedIn: false,
	savedRecipes: [],
};

export const fetchLandingPageContentAsync = createAsyncThunk(
	"content/fetchLandingPage",
	async () => {
		const response = await fetchLandingPageContent();
		return response;
	}
);

export const fetchFAQAsync = createAsyncThunk("content/fetchFAQ", async () => {
	const response = await fetchFAQ();
	return response;
});

export const fetchActionTextAsync = createAsyncThunk(
	"content/fetchActionText",
	async () => {
		const response = await fetchActionTextContent();
		return response;
	}
);

export const fetchSavedRecipesAsync = createAsyncThunk(
	"user/fetchSavedRecipes",
	async () => {
		const response = await fetchSavedRecipes();
		return response;
	}
);

export const deleteRecipeAsync = createAsyncThunk(
	"user/deleteRecipe",
	async (id) => {
		const response = await deleteRecipe(id);
		if (response.status === 200) {
			toast(<Toast messageKey="deleteRecipeSuccess" />);
		} else {
			toast(<Toast messageKey="deleteRecipeError" />);
		}
		return response;
	}
);

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setIsSignedIn(state) {
			state.isSignedIn = !state.isSignedIn;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLandingPageContentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchLandingPageContentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.landingPage = action.payload;
			})
			.addCase(fetchFAQAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchFAQAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.FAQ = action.payload;
			})
			.addCase(fetchActionTextAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchActionTextAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.actionText = action.payload;
			})
			.addCase(fetchSavedRecipesAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchSavedRecipesAsync.fulfilled, (state, action) => {
				state.status = "idle";
				if (action.payload) {
					state.savedRecipes = action.payload;
				}
			})
			.addCase(deleteRecipeAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteRecipeAsync.fulfilled, (state, action) => {
				state.status = "idle";
				console.log(state.savedRecipes);
				state.savedRecipes = state.savedRecipes.filter(
					({ id }) => parseInt(id) !== parseInt(action.payload.data)
				);
				console.log(state.savedRecipes);
			});
	},
});

export const { setIsSignedIn } = userSlice.actions;

export const selectIsSignedIn = (state) => state.user.isSignedIn;
export const selectSavedRecipes = (state) => state.user.savedRecipes;

export default userSlice.reducer;
