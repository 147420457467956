import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUmbracoUrl } from "../../app/axios";
import {
	fetchRecipe,
	fetchSavedRecipe,
	postReviewAndRating,
} from "./recipeAPI";

const initialState = () => ({
	selectedFlavour: "",
	selectedABV: "",
	selectedAroma: "",
	selectedColour: "",
	selectedStyle: "",
	selectedExtract: [],
	selectedHop: [],
	selectedYeast: [],
	selectedVolume: {},
	selectedSuggestion: "",
	recipe: {},
	isSavedRecipe: false,
	isLoading: false,
});

export const fetchRecipeAsync = createAsyncThunk(
	"builtRecipe/fetchRecipe",
	async (arg, { getState }) => {
		const state = getState();
		console.log("State", state);
		const response = await fetchRecipe({
			style:
				(state.builtRecipe.selectedStyle &&
					state.builtRecipe.selectedStyle.id) ||
				"",
			flavour:
				(state.builtRecipe.selectedFlavour &&
					state.builtRecipe.selectedFlavour.id) ||
				"",
			aroma:
				(state.builtRecipe.selectedAroma &&
					state.builtRecipe.selectedAroma.id) ||
				"",
			colour:
				(state.builtRecipe.selectedColour &&
					state.builtRecipe.selectedColour.id) ||
				"",
			abv:
				(state.builtRecipe.selectedABV && state.builtRecipe.selectedABV.id) ||
				"",
			yeast:
				(state.builtRecipe.selectedYeast &&
					state.builtRecipe.selectedYeast.length > 0 &&
					state.builtRecipe.selectedYeast.map(({ id, value }) => ({
						id,
						value,
					}))) ||
				[],
			hop:
				(state.builtRecipe.selectedHop &&
					state.builtRecipe.selectedHop.length > 0 &&
					state.builtRecipe.selectedHop.map(({ id, value }) => ({
						id,
						value,
					}))) ||
				[],
			extract:
				(state.builtRecipe.selectedExtract &&
					state.builtRecipe.selectedExtract.length > 0 &&
					state.builtRecipe.selectedExtract.map(({ id, value }) => ({
						id,
						value,
					}))) ||
				[],
			volume:
				(state.builtRecipe.selectedVolume &&
					state.builtRecipe.selectedVolume.label) ||
				"",
		});

		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const fetchSavedRecipeAsync = createAsyncThunk(
	"builtRecipe/fetchSavedRecipe",
	async (id) => {
		const response = await fetchSavedRecipe(id);

		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const updateRatingAsync = createAsyncThunk(
	"builtRecipe/updateRating",
	async ({ review, rating, name }, { getState }) => {
		const state = getState();
		const ratingData = {
			id: state.builtRecipe.recipe.id,
			recipeReview: review,
			recipeRating: rating,
			recipeName: name,
		};

		const response = await postReviewAndRating(ratingData);

		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const builtRecipeSlice = createSlice({
	name: "builtRecipe",
	initialState: initialState(),
	reducers: {
		selectFlavour: (state, action) => {
			state.selectedFlavour = action.payload;
			state.selectedFlavour.type = "Flavour";
			state.selectedExtract = [];
		},
		selectColour: (state, action) => {
			state.selectedColour = action.payload;
			state.selectedColour.type = "Colour";
		},
		selectABV: (state, action) => {
			state.selectedABV = action.payload;
			state.selectedABV.type = "ABV";
		},
		selectAroma: (state, action) => {
			state.selectedAroma = action.payload;
			state.selectedAroma.type = "Aroma";
			state.selectedHop = [];
		},
		selectStyle: (state, action) => {
			state.selectedStyle = action.payload;
			state.selectedStyle.type = "Style";
			state.selectedYeast = [];
		},
		selectExtract: (state, action) => {
			const index = state.selectedExtract
				.map((extract) => extract.id)
				.indexOf(action.payload.id);
			console.log("Index", index);
			if (index > -1) {
				if (action.payload.value === 0) {
					state.selectedExtract = state.selectedExtract.filter(
						(item) => item.id !== action.payload.id
					);
				} else {
					state.selectedExtract[index] = action.payload;
				}
			} else {
				console.log("Extracted", state.selectedExtract, action.payload);

				if (Array.isArray(action.payload)) {
					state.selectedExtract = [...state.selectedExtract, ...action.payload];
				} else {
					state.selectedExtract = [...state.selectedExtract, action.payload];
				}
			}
		},
		selectHop: (state, action) => {
			const index = state.selectedHop
				.map((hop) => hop.id)
				.indexOf(action.payload.id);

			if (index > -1) {
				if (action.payload.value === 0) {
					state.selectedHop = state.selectedHop.filter(
						(item) => item.id !== action.payload.id
					);
				} else {
					state.selectedHop[index] = action.payload;
				}
			} else {
				state.selectedHop = [...state.selectedHop, action.payload];
			}
		},
		selectYeast: (state, action) => {
			const index = state.selectedYeast
				.map((yeast) => yeast.id)
				.indexOf(action.payload.id);

			if (index > -1) {
				if (action.payload.value === 0) {
					state.selectedYeast = state.selectedYeast.filter(
						(item) => item.id !== action.payload.id
					);
				} else {
					state.selectedYeast[index] = action.payload;
				}
			} else {
				state.selectedYeast = [...state.selectedYeast, action.payload];
			}
		},
		selectVolume: (state, action) => {
			state.selectedVolume = action.payload;
		},
		selectSuggestion: (state, action) => {
			state.selectedSuggestion = action.payload;
		},
		resetSelections: (state, action) => initialState(),
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setRecipeName: (state, action) => {
			state.recipe.recipeName = action.payload;
		},
		setShowReview: (state, action) => {
			state.isSavedRecipe = action.payload;
		},
		setRecipeId: (state, action) => {
			state.recipe.id = action.payload;
		},
		setSachets(state, { payload }) {
			console.log("Setting Sacchets STate", payload.style);
			state.selectedStyle.numberOfSachets = payload.allStyles.find(
				({ id }) => id === state.selectedStyle.id
			).numberOfSachets;
		},
		setSelectedSavedRecipe: (state, action) => {
			state.isSavedRecipe = true;
			state.recipe = action.payload;
			const { label, cardColourRGB, id, description, images } =
				action.payload.colour;
			const colour = {
				label,
				cardColourRGB,
				id,
				description,
				img: images && baseUmbracoUrl + images[0],
				type: "Colour",
				isBackgroundImage: true,
			};
			state.selectedColour = colour;
			const {
				id: abvID,
				label: abvLavel,
				value,
				description: abvDesc,
			} = action.payload.abv;
			const abv = {
				id: abvID,
				label: abvLavel,
				value: value,
				description: abvDesc,
				images: null,
				isBackground: false,
				cardColourRGB: "fbf3dc",
				productDescription: null,
				keyFeatures: null,
				type: "ABV",
			};
			state.selectedABV = abv;
			const {
				id: aromaID,
				label: aromaLavel,
				value: aromaValue,
				description: aromaDesc,
				images: aromaImages,
			} = action.payload.aroma;
			const aroma = {
				id: aromaID,
				label: aromaLavel,
				value: aromaValue,
				description: aromaDesc,
				img: aromaImages && baseUmbracoUrl + aromaImages,
				isBackground: false,
				cardColourRGB: "fbf3dc",
				productDescription: null,
				keyFeatures: null,
				type: "Aroma",
			};
			state.selectedAroma = aroma;
			const {
				id: flavourID,
				label: flavourLavel,
				value: flavourValue,
				description: flavourDesc,
				images: flavourImages,
			} = action.payload.baseFlavour;
			const flavour = {
				id: flavourID,
				label: flavourLavel,
				value: flavourValue,
				description: flavourDesc,
				isBackground: false,
				cardColourRGB: "fbf3dc",
				productDescription: null,
				keyFeatures: null,
				img: flavourImages && baseUmbracoUrl + flavourImages[0],
				type: "Flavour",
			};
			state.selectedFlavour = flavour;
			const {
				id: styleID,
				label: styleLavel,
				description: styleDesc,
				images: styleImages,
			} = action.payload.style;
			const style = {
				label: styleLavel,
				img: styleImages && baseUmbracoUrl + styleImages[0],
				cardColourRGB: "fbf3dc",
				id: styleID,
				description: styleDesc,
			};
			state.selectedStyle = style;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRecipeAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchRecipeAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.recipe = action.payload;
				const { label, cardColourRGB, id, description, images } =
					action.payload.colour;
				const colour = {
					label,
					cardColourRGB,
					id,
					description,
					img: images && baseUmbracoUrl + images[0],
					type: "Colour",
					isBackgroundImage: true,
				};
				state.selectedColour = colour;
			})
			.addCase(fetchRecipeAsync.rejected, (state, action) => {
				state.status = "failed";
				console.warn(action.error);
			})
			.addCase(fetchSavedRecipeAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchSavedRecipeAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.isSavedRecipe = true;
				state.recipe = action.payload;
				const { label, cardColourRGB, id, description, images } =
					action.payload.colour;
				const colour = {
					label,
					cardColourRGB,
					id,
					description,
					img: images && baseUmbracoUrl + images[0],
					type: "Colour",
					isBackgroundImage: true,
				};
				state.selectedColour = colour;
				const {
					id: abvID,
					label: abvLavel,
					value,
					description: abvDesc,
				} = action.payload.abv;
				const abv = {
					id: abvID,
					label: abvLavel,
					value: value,
					description: abvDesc,
					images: null,
					isBackground: false,
					cardColourRGB: "fbf3dc",
					productDescription: null,
					keyFeatures: null,
					type: "ABV",
				};
				state.selectedABV = abv;
				const {
					id: flavourID,
					label: flavourLavel,
					value: flavourValue,
					description: flavourDesc,
					images: flavourImages,
				} = action.payload.baseFlavour;
				const flavour = {
					id: flavourID,
					label: flavourLavel,
					value: flavourValue,
					description: flavourDesc,
					isBackground: false,
					cardColourRGB: "fbf3dc",
					productDescription: null,
					keyFeatures: null,
					img: flavourImages && baseUmbracoUrl + flavourImages[0],
					type: "Flavour",
				};
				state.selectedFlavour = flavour;
				const {
					id: aromaID,
					label: aromaLavel,
					value: aromaValue,
					description: aromaDesc,
					images: aromaImages,
				} = action.payload.aroma;
				const aroma = {
					id: aromaID,
					label: aromaLavel,
					value: aromaValue,
					description: aromaDesc,
					img: aromaImages && baseUmbracoUrl + aromaImages,
					isBackground: false,
					cardColourRGB: "fbf3dc",
					productDescription: null,
					keyFeatures: null,
					type: "Aroma",
				};
				state.selectedAroma = aroma;
				const {
					id: styleID,
					label: styleLavel,
					description: styleDesc,
					images: styleImages,
				} = action.payload.style;
				const style = {
					label: styleLavel,
					img: styleImages && baseUmbracoUrl + styleImages[0],
					cardColourRGB: "fbf3dc",
					id: styleID,
					description: styleDesc,
				};
				state.selectedStyle = style;
			})
			.addCase(fetchSavedRecipeAsync.rejected, (state, action) => {
				state.status = "failed";
				console.warn(action.error);
			})
			.addCase(updateRatingAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateRatingAsync.fulfilled, (state, action) => {
				state.status = "idle";
				console.log(action.payload);
			})
			.addCase(updateRatingAsync.rejected, (state, action) => {
				state.status = "failed";
				console.warn(action.error);
			});
	},
});

export const {
	selectFlavour,
	selectStyle,
	selectABV,
	selectAroma,
	selectColour,
	selectExtract,
	resetSelections,
	selectVolume,
	selectSuggestion,
	selectHop,
	selectYeast,
	setIsLoading,
	setShowReview,
	setSelectedSavedRecipe,
	setRecipeName,
	setRecipeId,
	setSachets,
} = builtRecipeSlice.actions;

export const selectSelectedFlavour = (state) =>
	state.builtRecipe.selectedFlavour;
export const selectSelectedColour = (state) => state.builtRecipe.selectedColour;
export const selectSelectedAroma = (state) => state.builtRecipe.selectedAroma;
export const selectSelectedABV = (state) => state.builtRecipe.selectedABV;
export const selectSelectedStyle = (state) => state.builtRecipe.selectedStyle;
export const selectSelectedExtract = (state) =>
	state.builtRecipe.selectedExtract;
export const selectSelectedYeast = (state) => state.builtRecipe.selectedYeast;
export const selectSelectedHop = (state) => state.builtRecipe.selectedHop;
export const selectSelectedVolume = (state) => state.builtRecipe.selectedVolume;
export const selectSelectedSuggestion = (state) =>
	state.builtRecipe.selectedSuggestion;
export const selectRecipe = (state) => [
	state.builtRecipe.selectedFlavour,
	state.builtRecipe.selectedAroma,
	state.builtRecipe.selectedColour,
	state.builtRecipe.selectedABV,
];
export const selectIsLoading = (state) => state.builtRecipe.isLoading;

export const selectFullRecipe = (state) => {
	return {
		style: state.builtRecipe.selectedStyle.id,
		flavour: state.builtRecipe.selectedFlavour.id,
		aroma: state.builtRecipe.selectedAroma.id,
		colour: state.builtRecipe.selectedColour.id,
		abv: state.builtRecipe.selectedABV.id,
		yeast:
			(state.builtRecipe.selectedYeast && state.builtRecipe.selectedYeast.id) ||
			"",
		hop:
			(state.builtRecipe.selectedHop && state.builtRecipe.selectedHop.id) || "",
		extract:
			(state.builtRecipe.selectedExtract &&
				state.builtRecipe.selectedExtract.id) ||
			"",
		volume: state.builtRecipe.selectedVolume.label,
	};
};

export const selectFullRecipeForSaving = (state) => {
	return {
		style: state.builtRecipe.selectedStyle.id,
		flavour: state.builtRecipe.selectedFlavour.id,
		aroma: state.builtRecipe.selectedAroma.id,
		colour: state.builtRecipe.selectedColour.id,
		abv: state.builtRecipe.selectedABV.id,
		yeast: state.builtRecipe.selectedYeast.map(({ id, value }) => ({
			id,
			value: parseInt(value),
		})),
		hop: state.builtRecipe.selectedHop.map(({ id, value }) => ({
			id,
			value: parseInt(value),
		})),
		extract: state.builtRecipe.selectedExtract.map(({ id, value }) => ({
			id,
			value: parseInt(value),
		})),
		volume: state.builtRecipe.selectedVolume.label,
	};
};

export const selectInstructions = (state) => state.builtRecipe.recipe;
export const selectEquipment = (state) => state.builtRecipe.recipe.equipment;
export const selectBuiltRecipeState = (state) => state;
export const selectIsSaveRecipe = (state) => state.builtRecipe.isSavedRecipe;

export const builtRecipeSelectors = {
	flavour: selectSelectedFlavour,
	colour: selectSelectedColour,
	aroma: selectSelectedAroma,
	abv: selectSelectedABV,
	style: selectSelectedStyle,
	extract: selectSelectedExtract,
	volume: selectSelectedVolume,
	recipe: selectRecipe,
	suggestion: selectSelectedSuggestion,
};

export default builtRecipeSlice.reducer;
