import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import StyledSelect from "../components/inputs/StyledSelect";
import { signUpForm, formLoginPost } from "../app/axios";
import DateFnsUtils from "@date-io/date-fns";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputAdornment, IconButton } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectActionText, toggleShowSignUpForm } from "../redux/content";
import Toast from "../components/toast/Toast";
import { toast } from "react-toastify";
import {
	StyledTextField,
	StyledLabel,
	Label,
	StyledCheckbox,
	StyledDatePicker,
} from "../components/loginForm/LoginInputs";
import { setIsSignedIn } from "../redux/user";

const validationSchema = yup.object({
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	password: yup
		.string()
		.required("Password is required")
		.min(6, "Password too short"),
	passwordMatch: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.min(6, "Password too short"),
});

const Form = () => {
	const [dobError, setDobError] = useState("");
	const text = useSelector(selectActionText);
	const [showPassword, setShowPassword] = useState(false);
	const handleMouseUpPassword = () => setShowPassword(false);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);
	const [showPasswordMatch, setShowPasswordMatch] = useState(false);
	const handleMouseUpPasswordMatch = () => setShowPasswordMatch(false);
	const handleMouseDownPasswordMatch = () =>
		setShowPasswordMatch(!showPasswordMatch);
	const history = useHistory();
	const dispatch = useDispatch();
	const [showSkip, setShowSkip] = useState(false);
	const location = useLocation();
	useEffect(() => {
		if (
			location.pathname === "/recipe-suggestions/ingredients" ||
			location.pathname === "/recipe-designer/ingredients"
		)
			setShowSkip(true);
	}, [location.pathname]);
	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			interest: "",
			dob: new Date(),
			terms: false,
			password: "",
			passwordMatch: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			delete values.passwordMatch;

			const response = await signUpForm("signup", values);
			console.log(response);
			if (response.status === 200) {
				try {
					const response = await formLoginPost({
						password: values.password,
						grant_type: "password",
						username: values.email,
					});
					const status = response.status;
					if (status === 200) {
						const { access_token, refresh_token } = response.data;
						if (access_token) {
							localStorage.setItem("access_token", access_token);
							localStorage.setItem("refresh_token", refresh_token);
							if (location.pathname === "/saved-recipes") {
								history.push("/");
							}
							toast(<Toast messageKey={"loginSuccess"} />);
							dispatch(setIsSignedIn());

							if (
								location.pathname === "/recipe-suggestions/ingredients" ||
								location.pathname === "/recipe-designer/ingredients"
							) {
								history.push(
									location.pathname.replace("ingredients", "recipe")
								);
							}
						} else {
							toast(<Toast messageKey={"loginError"} />);
						}
					}
				} catch (e) {
					console.warn(e);
				}
				history.push("recipe");
				toast(<Toast messageKey="signUpSuccess" />);
			} else {
				toast(<Toast messageKey="signUpError" />);
			}
			dispatch(toggleShowSignUpForm(false));
		},
	});

	const date = new Date();
	date.setFullYear(date.getFullYear() - 18);

	return (
		<div className="container-lg">
			<div className="row">
				<form
					onSubmit={formik.handleSubmit}
					onMouseUp={() => {
						handleMouseUpPasswordMatch();
						handleMouseUpPassword();
					}}
				>
					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ">
								{" "}
								<h1>Sign up</h1>
							</div>
						</div>
					)}
					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
						<StyledLabel
							control={
								<StyledTextField
									id="firstName"
									name="firstName"
									value={formik.values.firstName}
									onChange={formik.handleChange}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your name here...."
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>What's your first name?</Label>}
							labelPlacement="top"
						/>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
						<StyledLabel
							control={
								<StyledTextField
									id="lastName"
									name="lastName"
									value={formik.values.lastName}
									onChange={formik.handleChange}
									style={{ margin: 8 }}
									placeholder="Type your name here...."
									variant="outlined"
									margin="normal"
									fullWidth // eslint-disable-line
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>What's your last name?</Label>}
							labelPlacement="top"
						/>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
						<StyledLabel
							control={
								<StyledTextField
									id="email"
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your email here...."
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>What's your email address?</Label>}
							labelPlacement="top"
						/>
					</div>
					<div className="col-12 col-lg-6 mx-auto d-flex flex-column justify-content-center form-group">
						<label className="d-block  mb-2" htmlFor="interest">
							What are you interested in?
						</label>

						<StyledSelect
							id="interest"
							name="interest"
							inputId="interest"
							isSearchable={false}
							value={formik.values.interest}
							onChange={(val) => {
								formik.setFieldValue("interest", val);
							}}
							options={[
								{
									value: "Malt Extract Brewing",
									label: "Malt Extract Brewing",
								},
								{
									value: "Partial Extract Brewing",
									label: "Partial Extract Brewing",
								},

								{ value: "All-grain Brewing", label: "All-grain Brewing" },
							]}
							className=" mb-2 "
						/>
					</div>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
							<StyledLabel
								control={
									<StyledDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date-picker-inline"
										openTo="year"
										value={formik.values.dob}
										onChange={(val) => {
											if (date < val) {
												setDobError("You must be at least 18 years old.");
											} else {
												setDobError("");
												formik.setFieldValue("dob", val);
											}
										}}
										KeyboardButtonProps={{
											"aria-label": "change date",
										}}
										fullWidth // eslint-disable-line
										style={{ margin: 8 }}
										placeholder="Type your date of birth here...."
										InputLabelProps={{
											shrink: true,
										}}
										inputVariant="outlined"
									/>
								}
								label={<Label>What's your date of birth?</Label>}
								labelPlacement="top"
							/>
						</div>
						{dobError && (
							<div className="col-12  col-lg-6 mx-auto d-flex justify-content-start form-group">
								<p className="error  MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
									{dobError}
								</p>
							</div>
						)}
					</MuiPickersUtilsProvider>
					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-4">
						<StyledLabel
							control={
								<StyledCheckbox
									id="terms"
									name="terms"
									checked={formik.values.terms}
									onChange={formik.handleChange}
									required
									disableRipple
								/>
							}
							label={
								<Label>
									<p>Lets stay in touch.</p>{" "}
									<p>
										Please tick this box to confirm that you are happy to be
										contacted for marketing purposes via email. Click here to
										view our{" "}
										<a
											id="privacy-link"
											rel="noreferrer"
											target="_blank"
											href="https://www.muntons.com/privacy-policy/"
										>
											Privacy Policy
										</a>
									</p>
								</Label>
							}
							labelPlacement="start"
						/>
					</div>
					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
						<StyledLabel
							control={
								<StyledTextField
									id="password"
									name="password"
									value={formik.values.password}
									onChange={formik.handleChange}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									helperText={formik.touched.password && formik.errors.password}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your password here...."
									margin="normal"
									type={showPassword ? "text" : "password"} // <-- This is where the magic happens
									InputProps={{
										// <-- This is where the toggle button is added.
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onMouseDown={handleMouseDownPassword}
													onMouseUp={handleMouseUpPassword}
												>
													{showPassword ? (
														<Visibility
															style={{ color: "#000", zIndex: "99" }}
														/>
													) : (
														<VisibilityOff
															style={{ color: "#000", zIndex: "99" }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									autoComplete="current-password"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Password</Label>}
							labelPlacement="top"
						/>
					</div>
					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group">
						<StyledLabel
							control={
								<StyledTextField
									id="passwordMatch"
									name="passwordMatch"
									value={formik.values.passwordMatch}
									onChange={formik.handleChange}
									error={
										formik.touched.passwordMatch &&
										Boolean(formik.errors.passwordMatch)
									}
									helperText={
										formik.touched.passwordMatch && formik.errors.passwordMatch
									}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your password here...."
									margin="normal"
									type={showPasswordMatch ? "text" : "password"} // <-- This is where the magic happens
									InputProps={{
										// <-- This is where the toggle button is added.
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onMouseDown={handleMouseDownPasswordMatch}
												>
													{showPasswordMatch ? (
														<Visibility
															style={{ color: "#000", zIndex: "99" }}
														/>
													) : (
														<VisibilityOff
															style={{ color: "#000", zIndex: "99" }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									autoComplete="current-password"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Password Confirmation</Label>}
							labelPlacement="top"
						/>
					</div>
					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-4 mt-4">
						<button type="submit" className="btn btn-secondary w-100">
							Create my recipe and register
						</button>
					</div>
					{showSkip && (
						<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-5">
							<p
								onClick={() => {
									history.push("recipe");
									dispatch(toggleShowSignUpForm());
								}}
								className="text-center cursor-pointer text-decoration-underline w-100"
							>
								Skip this
							</p>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default Form;
