import React from "react";
import {
	selectSelectedExtract,
	selectSelectedYeast,
	selectSelectedHop,
	selectInstructions,
	selectIsSaveRecipe,
} from "../../redux/builtRecipe";
import { useSelector } from "react-redux";
import sugarImg from "../../assets/img/sugar.png";
import AmountOfPints from "./AmountOfPints";
import { baseUmbracoUrl } from "../../app/axios";

const RecipeIngredients = () => {
	const recipe = useSelector(selectInstructions);
	const extract = useSelector(selectSelectedExtract);
	const hop = useSelector(selectSelectedHop);
	const yeast = useSelector(selectSelectedYeast);
	const isSavedRecipe = useSelector(selectIsSaveRecipe);
	const orderIngredients = (ingredientsArray) => {
		console.log(ingredientsArray);
		let orderedIngredients = [];
		const premium = ingredientsArray.filter((ing) =>
			ing?.productTypeFlag?.includes("Premium")
		);
		const standard = ingredientsArray.filter((ing) =>
			ing?.productTypeFlag?.includes("Standard")
		);
		const rest = ingredientsArray.filter(
			(ing) =>
				ing?.productTypeFlag?.length == 0 ||
				typeof ing?.productTypeFlag == "undefined"
		);

		orderedIngredients = [...premium, ...standard, ...rest];
		console.log(orderedIngredients);
		return orderedIngredients;
	};

	const {
		hops: recipeHops,
		yeast: recipeYeast,
		extract: recipeExtract,
		ingredients,
	} = recipe;
	console.log(extract, recipeExtract);
	return isSavedRecipe ? (
		<div className="row instruction-selection justify-content-center">
			<AmountOfPints />
			<div className="col-12 mb-4">
				<h2>Your ingredients</h2>
			</div>

			<div className="recipe-option-area row">
				{recipeExtract &&
					orderIngredients(recipeExtract).map((extract, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								{extract.images && extract.images[0] && (
									<img
										alt="extract "
										className="img-fluid w-100"
										src={baseUmbracoUrl + extract.images[0] || recipeExtract[0]}
									/>
								)}
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{extract.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity: {ingredients.numberOfCansOfExtract}
											cans
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{recipeYeast &&
					orderIngredients(recipeYeast).map((yeast, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								{yeast.images && yeast.images[0] && (
									<img
										alt="yeast"
										className="img-fluid w-100"
										src={baseUmbracoUrl + yeast.images[0]}
									/>
								)}
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{yeast.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity: {ingredients.numberOfSachetsOfYeast} sachets
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{recipeHops &&
					orderIngredients(recipeHops).map((hop, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								{hop.images && hop.images[0] && (
									<img
										alt="hops"
										className="img-fluid w-100"
										src={baseUmbracoUrl + hop.images[0]}
									/>
								)}
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{hop.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity: {ingredients.numberOfSachetsOfHops}
											sachets
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{recipe && recipe.ingredients && recipe.ingredients.amountOfSugar && (
					<div className="col-12 col-md-6 recipe-option mb-4 row">
						<div className="col-4">
							<img alt="alt text" className="img-fluid w-100" src={sugarImg} />
						</div>
						<div className="col-8 ps-5">
							<div className="row">
								<div className="col-8">
									<h4>Sugar</h4>
								</div>

								<div className="col-12 mt-3">
									<p>Quantity: {recipe.ingredients.amountOfSugar}</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	) : (
		<div className="row instruction-selection justify-content-center">
			<AmountOfPints />
			<div className="col-12 mb-4">
				<h2>Your ingredients</h2>
			</div>

			<div className="recipe-option-area row">
				{extract &&
					orderIngredients(extract).map((extract, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								<img
									alt="alt text"
									className="img-fluid w-100"
									src={extract.img || baseUmbracoUrl + extract.images[0]}
								/>
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{extract.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity:{" "}
											{recipeExtract &&
												recipeExtract.find((ex) => ex.name === extract.label) &&
												recipeExtract.find((ex) => ex.name === extract.label)
													.qty}{" "}
											cans
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{yeast &&
					orderIngredients(yeast).map((yeast, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								<img
									alt="alt text"
									className="img-fluid w-100"
									src={yeast.img || baseUmbracoUrl + yeast.images[0]}
								/>
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{yeast.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity:{" "}
											{recipeYeast &&
												recipeYeast.find((ye) => ye.name === yeast.label) &&
												recipeYeast.find((ye) => ye.name === yeast.label)
													.qty}{" "}
											sachets
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{hop &&
					orderIngredients(hop).map((hop, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								<img
									alt="alt text"
									className="img-fluid w-100"
									src={hop.img || baseUmbracoUrl + hop.images[0]}
								/>
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{hop.label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>
											Quantity:{" "}
											{recipeHops &&
												recipeHops.find((ho) => ho.name === hop.label) &&
												recipeHops.find((ho) => ho.name === hop.label).qty}{" "}
											sachets
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				{recipe && recipe.ingredients && recipe.ingredients.amountOfSugar && (
					<div className="col-12 col-md-6 recipe-option mb-4 row">
						<div className="col-4">
							<img alt="alt text" className="img-fluid w-100" src={sugarImg} />
						</div>
						<div className="col-8 ps-5">
							<div className="row">
								<div className="col-8">
									<h4>Sugar</h4>
								</div>

								<div className="col-12 mt-3">
									<p>Quantity: {recipe.ingredients.amountOfSugar}</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecipeIngredients;
