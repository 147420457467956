import React, { useRef, useState, useEffect } from "react";
import { useMeasure } from "./use-measure";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Pager } from "./Pager";
import ShareRecipe from "./ShareRecipe";
import RecipeIngredients from "../common/RecipeIngredients";
import Overview from "./sections/Overview";
import Equipment from "./sections/Equipment";
import Instructions from "./sections/Instructions";

const TabContainer = styled.div`
	overflow-y: hidden;
	box-shadow: none;
`;

const TabList = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	position: relative;
	background-color: #f7f7f7;
	margin-bottom: 1rem;
	justify-content: space-between;
	border-radius: 30px;
`;

const TabItem = styled(motion.button)`
	white-space: nowrap;
	-webkit-appearance: none;
	box-sizing: border-box;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
	user-select: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	box-shadow: none;
	cursor: pointer;
	text-decoration: none;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	padding: 10px 0;
	box-sizing: border-box;
	width: 25%;
	-webkit-font-smoothing: antialiased;
	text-size-adjust: none;
	text-overflow: ellipsis;
	color: ${(p) => (p.isActive ? "#fff" : "#000")};
	margin: 0px;
	overflow: hidden;
	background-color: transparent;
	font-weight: ${(p) => (p.isActive ? "700" : "400")};
	line-height: 1.5;
	text-align: center;
	position: relative;
	z-index: 999;

	@media (max-width: 370px) {
		font-size: 0.7rem;
	}
`;

const Slider = styled(motion.div)`
	height: 100%;
	border-radius: 30px;
	margin-left: 2px;
	margin-right: 2px;
	bottom: 0;
	position: absolute;
	background: #eaaa21;
	transition: all 0.5s ease;
	z-index: -2;
	transform-origin: center center;

	z-index: 100;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
`;

const TabContainerInner = styled.div`
	background: #fff;
	padding: 3rem 0;
`;

const tabs = ["Overview", "Ingredients", "Equipment", "Method"];

export function Tabs() {
	const [value, setValue] = useState(0);
	const [activeState, setActiveState] = useState(0);
	const childRefs = useRef(new Map());
	const tabListRef = useRef();
	const [slider, setSlider] = useState({ left: 0, right: 0 });
	const { bounds, ref } = useMeasure();
	const [isSliding, setIsSliding] = useState(false);

	// measure our elements
	useEffect(() => {
		const target = childRefs.current.get(value);
		const container = tabListRef.current;
		if (target) {
			const cRect = container.getBoundingClientRect();

			// when container is `display: none`, width === 0.
			// ignore this case
			if (cRect.width === 0) {
				return;
			}

			const tRect = target.getBoundingClientRect();
			const left = tRect.left - cRect.left;
			const right = cRect.right - tRect.right;

			setSlider({
				hasValue: true,
				left: left,
				right: right,
			});
			setIsSliding(true);
			setTimeout(() => setIsSliding(false), 2000);
		}
	}, [value, bounds]);

	const onClickHandler = (i) => {
		setValue(i);
		setTimeout(() => {
			setActiveState(i);
		}, 200);
	};

	return (
		<>
			<div className="">
				<TabContainer ref={ref}>
					<TabList ref={tabListRef}>
						{tabs.map((tab, i) => (
							<TabItem
								key={tab}
								isActive={i === activeState}
								transition={{ duration: 0.1 }}
								ref={(el) => childRefs.current.set(i, el)}
								onClick={() => onClickHandler(i)}
							>
								{tab}
							</TabItem>
						))}
						{slider.hasValue && (
							<Slider
								positionTransition={{
									bounceDamping: 3,
								}}
								initial={false}
								style={{
									left: slider.left,
									right: slider.right,
								}}
								className={isSliding ? "isSliding" : ""}
							/>
						)}
					</TabList>
				</TabContainer>
				<Pager value={value}>
					{tabs.map((tab) => (
						<TabContainerInner key={tab} className="px-3 px-xl-5">
							{/* Move these tabs into individual components  ss */}
							{tab === "Overview" && (
								<>
									<Overview />

									<ShareRecipe />
								</>
							)}
							{tab === "Ingredients" && (
								<>
									<RecipeIngredients />

									<ShareRecipe />
								</>
							)}
							{tab === "Equipment" && (
								<>
									<Equipment />
									<ShareRecipe />
								</>
							)}
							{tab === "Method" && (
								<>
									<Instructions />

									<ShareRecipe />
								</>
							)}
						</TabContainerInner>
					))}
				</Pager>
			</div>
		</>
	);
}
