import React, { useState, useEffect } from "react";
import Card from "./Card";
import BottomNav from "../bottomNav/BottomNav";
import { motion } from "framer-motion";
import ActionText from "../../components/actionText/ActionText";
import DrinkDescription from "../actionText/DrinkDescription";

const cardsVariants = {
	visible: {
		opacity: 1,
		transition: {
			type: "spring",
			delay: 0.3,
		},
	},
	hidden: {
		opacity: 0,
	},
};

const Cards = ({
	list,
	nextStep,
	onClickHandler = () => {},
	selection,
	isConfirmationPage,
	bottomNav,
	page,
	mobile,
	isRecipePage,
	showDescription,
}) => {
	const [selectedCard, setSelectedCard] = useState(
		(selection && selection.label) || null
	);

	useEffect(() => {
		setSelectedCard((selection && selection.label) || null);
	}, [selection]);
	return (
		<div
			className={
				isConfirmationPage
					? `option-group ${
							mobile
								? "d-block d-xl-none"
								: `${isRecipePage ? "col-8 d-none d-xl-block px-2" : ""}`
					  }`
					: `option-group col-12 col-xl-7 ${mobile ? "d-block d-md-none" : ""}`
			}
		>
			{showDescription && <DrinkDescription />}
			{bottomNav && <ActionText page={page} />}
			{list && (
				<motion.div
					variants={cardsVariants}
					initial="hidden"
					animate="visible"
					className={`row text-center ${
						isConfirmationPage
							? "d-flex flex-sm-nowrap card-container-variables overflow-y-hidden  pt-4 pb-5"
							: "d-block card-container-variables py-4"
					} d-xl-flex`}
				>
					{list &&
						list.map((item, i) => (
							<Card
								key={i}
								data={item}
								nextStep={nextStep}
								onClickHandler={(type) => onClickHandler(type)}
								isCardSelected={item && item.label === selectedCard}
								setSelectedCard={setSelectedCard}
								isConfirmationPage={isConfirmationPage}
								mobile={mobile}
								isRecipePage={isRecipePage}
							/>
						))}
				</motion.div>
			)}
			{bottomNav && <BottomNav />}
		</div>
	);
};

export default Cards;
