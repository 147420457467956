import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedIngredient } from "../redux/ingredients";
import { baseUmbracoUrl } from "../app/axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const TitleContainer = styled.div`
	position: relative;
`;

const BackButton = styled.button`
	position: absolute;
	left: 20px;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;

const IngredientsDetails = () => {
	const { label, images, description, keyFeatures, productDescription } =
		useSelector(selectSelectedIngredient);
	const history = useHistory();
	const goBack = () => {
		history.push("/our-ingredients/");
	};

	return (
		<main className="container-xxl">
			<div className="row">
				<TitleContainer className="text-center pt-5">
					<BackButton onClick={goBack}>Back</BackButton>
					<h1 className="mb-3 d-block d-lg-none">{label}</h1>
				</TitleContainer>
				<div className="col-12 col-lg-6 text-center pt-5 mb-5">
					{images && images[0] && (
						<div>
							<img src={baseUmbracoUrl + images[0]} alt="ingredient" />
						</div>
					)}
				</div>
				<div className="col-12 col-lg-6">
					<div className="col-12">
						<h1 className="mb-3 d-none d-lg-block">{label}</h1>
						<p>{description}</p>
					</div>
					<div className="col-12">
						<h5>Key Features</h5>
						{keyFeatures &&
							keyFeatures.map((feature, i) => (
								<div key={i}>
									<h6>{feature.split(" -")[0] || ""}</h6>
									<p>{feature.split(" -")[1] || ""}</p>
								</div>
							))}
					</div>
					<div className="col-12">
						<p>Product Description</p>
						<div dangerouslySetInnerHTML={{ __html: productDescription }}></div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default IngredientsDetails;
