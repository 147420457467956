import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import flavourSlice from "../redux/flavour";
import styleSlice from "../redux/style";
import colourSlice from "../redux/colour";
import aromaSlice from "../redux/aroma";
import abvSlice from "../redux/abv";
import builtRecipeSlice from "../redux/builtRecipe";
import ingredientsSlice from "../redux/ingredients";
import contentSlice from "../redux/content";
import suggestionsSlice from "../redux/suggestions";
import userSlice from "../redux/user";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
};

const rootReducer = combineReducers({
	style: styleSlice,
	flavour: flavourSlice,
	colour: colourSlice,
	aroma: aromaSlice,
	abv: abvSlice,
	builtRecipe: builtRecipeSlice,
	ingredients: ingredientsSlice,
	content: contentSlice,
	suggestions: suggestionsSlice,
	user: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export let storeRef = store;
