import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledSelect from "../../components/inputs/StyledSelect";
import {
	fetchExtractsAsync,
	fetchVolumesAsync,
	selectVolumes,
} from "../../redux/ingredients";
import { selectFilteredHops } from "../../redux/aroma";
import { selectFilteredYeasts } from "../../redux/style";
import { selectFilteredExtracts } from "../../redux/flavour";
import {
	selectExtract,
	selectVolume,
	selectHop,
	selectYeast,
	selectSelectedYeast,
	selectSelectedHop,
	selectSelectedExtract,
	selectSelectedVolume,
	selectSelectedStyle,
	selectSelectedABV,
} from "../../redux/builtRecipe";
import { selectActionText } from "../../redux/content";
import ActionText from "../../components/actionText/ActionText";
import IngredientsList from "../../components/common/IngredientsList";
import Modal from "../../components/common/Modal";
import Form from "../../routes/Form";
import { motion } from "framer-motion";
import { matrix } from "../../components/common/ExtractABVMatrix";
import { toggleLoginForm } from "../../redux/content";
import { selectIsSignedIn } from "../../redux/user";
import { useHistory } from "react-router-dom";

const Ingredients = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [errors, setErrors] = useState({
		volume: "",
		extract: "",
		hop: "",
		yeast: "",
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const extracts = useSelector(selectFilteredExtracts);
	const hops = useSelector(selectFilteredHops);
	const yeasts = useSelector(selectFilteredYeasts);
	const volumes = useSelector(selectVolumes);
	const isSignedIn = useSelector(selectIsSignedIn);
	const hopsVolumes = useSelector(selectSelectedStyle);
	const selectedYeast = useSelector(selectSelectedYeast);
	const selectedHop = useSelector(selectSelectedHop);
	const selectedExtract = useSelector(selectSelectedExtract);
	const selectedVolume = useSelector(selectSelectedVolume);

	const actionText = useSelector(selectActionText);
	const abv = useSelector(selectSelectedABV);

	const YeastsMap = {
		10: 1,
		20: 1,
		30: 1,
		40: 1,
		50: 2,
		60: 2,
		70: 2,
		80: 2,
		90: 3,
		100: 3,
	};

	useEffect(() => {
		dispatch(fetchExtractsAsync());
		dispatch(fetchVolumesAsync());
		window.scrollTo(0, 0);
	}, [dispatch]);

	const onChangeExtractHandler = (extractName) => {
		setErrors({ ...errors, extract: "" });
		dispatch(selectExtract(extractName));
	};

	const onChangeHopHandler = (hopName) => {
		setErrors({ ...errors, hop: "" });
		dispatch(selectHop(hopName));
	};

	const onChangeYeastHandler = (yeastName) => {
		setErrors({ ...errors, yeast: "" });
		dispatch(selectYeast(yeastName));
	};

	const onClickHandler = () => {
		const extractCheck =
			selectedExtract &&
			selectedExtract.reduce(
				(acc, curr) => parseInt(acc) + parseInt(curr.value),
				0
			) === matrix[abv.label][selectedVolume.label];

		const hopCheck =
			selectedHop &&
			selectedHop.reduce(
				(acc, curr) => parseInt(acc) + parseInt(curr.value),
				0
			) === hopsVolumes.numberOfSachets[selectedVolume.label];
		const yeastCheck =
			selectedYeast &&
			selectedYeast.reduce(
				(acc, curr) => parseInt(acc) + parseInt(curr.value),
				0
			) === YeastsMap[selectedVolume.label];
		setErrors({
			extract: extractCheck
				? ""
				: "Please select the correct amount of extract to continue",
			volume: selectedVolume
				? ""
				: "Please select a number of pints to continue",
			hop: hopCheck
				? ""
				: "Please select the correct amount of hop to continue",
			yeast: yeastCheck
				? ""
				: "Please select the correct amount of yeast to continue",
		});

		const extractsRequired = extracts ? extracts && extractCheck : true;
		const hopsRequired = hops ? hops && hopCheck : true;
		const yeastsRequired = yeasts ? yeasts && yeastCheck : true;

		if (extractsRequired && hopsRequired && yeastsRequired && selectedVolume) {
			if (isSignedIn) {
				history.push("recipe");
			} else {
				dispatch(toggleLoginForm());
			}
		}
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,

			backgroundColor: state.isSelected ? "transparent" : "transparent",
			color: state.isSelected ? "#000" : "#000",
			fontWeight: state.isSelected ? "bold" : "normal",
		}),
	};
	console.log(abv);
	return (
		<main className="container-xxl">
			<ActionText page="ingredients" showBoth />
			<div className="row justify-content-center py-4 mb-4">
				<div className="col-12 col-sm-6 ">
					<StyledSelect
						options={volumes}
						onChange={(vol) => dispatch(selectVolume(vol))}
						isClearable
						styles={customStyles}
						classNamePrefix="styled-select"
						isSearchable={false}
						value={selectedVolume}
					/>
					{errors && errors.volume && <p className="error">{errors.volume}</p>}
				</div>
			</div>

			{selectedVolume && Object.keys(selectedVolume).length > 0 && (
				<motion.div
					initial={{ opacity: 0, translateY: -200 }}
					animate={{ opacity: 1, translateY: 0 }}
					id="recipe-instructions"
					className="row justify-content-center"
				>
					<div className="col-12 mb-4">
						<h2>To make this recipe you will need</h2>
					</div>
					<IngredientsList
						ingredients={extracts}
						onChangeHandler={onChangeExtractHandler}
						type="Extract"
						selectedIngredient={selectedExtract}
						error={errors.extract}
						text={actionText.extract}
						selectedVolume={selectedVolume}
						numberOfSachets={matrix[abv.label]}
					/>
					<IngredientsList
						ingredients={hops}
						onChangeHandler={onChangeHopHandler}
						type="Hops"
						selectedIngredient={selectedHop}
						error={errors.hop}
						text={actionText.hop}
						selectedVolume={selectedVolume}
						numberOfSachets={hopsVolumes.numberOfSachets}
					/>
					<IngredientsList
						ingredients={yeasts}
						onChangeHandler={onChangeYeastHandler}
						type="Yeasts"
						selectedIngredient={selectedYeast}
						error={errors.yeast}
						text={actionText.yeast}
						selectedVolume={selectedVolume}
						numberOfSachets={YeastsMap}
					/>
					<div className="col-12 offset-md-8 col-md-4 mb-5">
						<button
							onClick={onClickHandler}
							className="btn btn-secondary w-100"
						>
							Confirm Selections
						</button>
					</div>
				</motion.div>
			)}
			<Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
				<Form />
			</Modal>
		</main>
	);
};

export default Ingredients;
