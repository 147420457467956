import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStylesAsync, selectStyles } from "../../redux/style";
import {
	selectStyle,
	builtRecipeSelectors,
	resetSelections,
} from "../../redux/builtRecipe";

import ActionText from "../../components/actionText/ActionText";
import FullPageCards from "../../components/cards/FullPageCards";

const Style = () => {
	const styles = useSelector(selectStyles);
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(resetSelections());
		dispatch(fetchStylesAsync());
		window.scrollTo(0, 0);
	}, [dispatch]);

	return (
		<main className="container-xxl">
			<ActionText page="style" showBoth />
			<ActionText page="style" mobile />
			<FullPageCards
				selection={selectedStyle}
				list={styles}
				onClickHandler={(type) => dispatch(selectStyle(type))}
				nextStep={"/recipe-designer/flavour"}
				styles
			/>
		</main>
	);
};

export default Style;
