export const matrix = {
	3: {
		10: 1,
		20: 1,
		30: 1,
		40: 1,
		50: 1,
		60: 1,
		70: 2,
		80: 2,
		90: 2,
		100: 2,
	},
	3.5: {
		10: 1,
		20: 1,
		30: 1,
		40: 1,
		50: 1,
		60: 2,
		70: 2,
		80: 2,
		90: 3,
		100: 3,
	},
	4: {
		10: 1,
		20: 1,
		30: 1,
		40: 1,
		50: 2,
		60: 2,
		70: 2,
		80: 3,
		90: 4,
		100: 4,
	},
	4.5: {
		10: 1,
		20: 1,
		30: 1,
		40: 1,
		50: 2,
		60: 2,
		70: 3,
		80: 3,
		90: 4,
		100: 4,
	},
	5: {
		10: 1,
		20: 1,
		30: 1,
		40: 2,
		50: 2,
		60: 3,
		70: 3,
		80: 4,
		90: 4,
		100: 4,
	},
	5.5: {
		10: 1,
		20: 1,
		30: 1,
		40: 2,
		50: 2,
		60: 3,
		70: 4,
		80: 4,
		90: 5,
		100: 5,
	},
	6: {
		10: 1,
		20: 1,
		30: 1,
		40: 2,
		50: 3,
		60: 3,
		70: 4,
		80: 5,
		90: 5,
		100: 5,
	},
	6.5: {
		10: 1,
		20: 1,
		30: 2,
		40: 2,
		50: 3,
		60: 4,
		70: 4,
		80: 5,
		90: 6,
		100: 6,
	},
	7: {
		10: 1,
		20: 1,
		30: 2,
		40: 2,
		50: 3,
		60: 4,
		70: 5,
		80: 5,
		90: 6,
		100: 6,
	},
	7.5: {
		10: 1,
		20: 1,
		30: 2,
		40: 3,
		50: 3,
		60: 4,
		70: 5,
		80: 6,
		90: 7,
		100: 7,
	},
	8: {
		10: 1,
		20: 1,
		30: 2,
		40: 3,
		50: 4,
		60: 5,
		70: 5,
		80: 6,
		90: 7,
		100: 7,
	},
	8.5: {
		10: 1,
		20: 1,
		30: 2,
		40: 3,
		50: 4,
		60: 5,
		70: 6,
		80: 7,
		90: 8,
		100: 8,
	},
	9: {
		10: 1,
		20: 2,
		30: 2,
		40: 3,
		50: 4,
		60: 5,
		70: 6,
		80: 7,
		90: 8,
		100: 8,
	},
	9.5: {
		10: 1,
		20: 2,
		30: 3,
		40: 4,
		50: 5,
		60: 6,
		70: 7,
		80: 8,
		90: 8,
		100: 8,
	},
	10: {
		10: 1,
		20: 2,
		30: 3,
		40: 4,
		50: 5,
		60: 6,
		70: 7,
		80: 8,
		90: 9,
		100: 10,
	},
};
