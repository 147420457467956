import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import CreateButton from "../common/CreateButton";
import useCurrentPath from "../common/useCurrentPath";
// import useWindowDimensions from "../common/useWindowDimensions";

const RecipeItemBorder = styled.div`
	border: 2px solid
		${({ isActive, isComplete, isIncomplete }) =>
			isActive && isComplete
				? "#E6EF71"
				: isActive && isIncomplete
				? "#832156"
				: isComplete
				? "#B3BC35"
				: isIncomplete
				? "#40102A"
				: ""};
	border-radius: 20px;
	display: grid;

	place-items: center;
	padding: 0.5rem 1rem;
	margin-bottom: 0.5rem;
	background: ${({ isActive, isComplete, isIncomplete }) =>
		isActive && isComplete
			? "#E6EF71"
			: isActive && isIncomplete
			? "#832156"
			: isComplete
			? "#B3BC35"
			: isIncomplete
			? "#40102A"
			: ""};
	transition: all 0.2s ease;
	h6 {
		margin-bottom: 0;
		font-size: 0.8rem;
		color: ${({ isActive, isComplete }) =>
			isActive && isComplete ? "#40102A" : "#fff"} !important;
	}

	@media (max-width: 380px) {
		padding: 0.5rem 0.5rem;
	}
`;

const Selection = styled.h6`
	color: #b3bc35 !important;
	text-align: center;
	@media (max-width: 380px) {
		font-size: 0.8rem !important;
	}
`;

const RecipeCards = ({ asideLayout, showButton }) => {
	const recipeItems = useSelector(builtRecipeSelectors.recipe);
	const recipeItemsCheck = recipeItems.some((item) => item);
	const [currentPath, url] = useCurrentPath();
	const items = ["Flavour", "Aroma", "ABV"];
	const [flavour, aroma, , abv] = recipeItems;
	const MAP = {
		flavour,
		aroma,
		abv,
	};
	const itemsRef = useRef([]);

	return (
		<AnimatePresence exitBeforeEnter initial={false}>
			{asideLayout ? (
				<div
					className={recipeItemsCheck ? "col-3 d-none d-xl-block" : "d-none"}
				>
					{items &&
						items.map(
							(item, i) =>
								item && (
									<motion.div
										key={`${item}-recipe-card`}
										initial={{ opacity: 0, scale: 0 }}
										animate={{ opacity: 1, scale: 1 }}
										exit={{ opacity: 0, scale: 0 }}
									>
										<Link to={`${currentPath}${item}`}>
											<RecipeItemBorder
												ref={(el) => (itemsRef.current[i] = el)}
												isActive={url
													.toLowerCase()
													.includes(item.toLowerCase())}
												isComplete={MAP[item.toLowerCase()]}
												isIncomplete={!MAP[item.toLowerCase()]}
											>
												<h6 className="text-center">{item}</h6>
											</RecipeItemBorder>
										</Link>
										<Selection>
											{MAP[item.toLowerCase()] && MAP[item.toLowerCase()].label}
										</Selection>
									</motion.div>
								)
						)}
				</div>
			) : (
				<div className="option-group">
					<div
						className={
							recipeItemsCheck
								? "d-flex flex-nowrap row mt-3"
								: "d-flex flex-nowrap row mt-3"
						}
					>
						{items &&
							items.map(
								(item, i) =>
									item && (
										<motion.div
											key={`${item}-recipe-card`}
											initial={{ opacity: 0, scale: 0 }}
											animate={{ opacity: 1, scale: 1 }}
											exit={{ opacity: 0, scale: 0 }}
											className="d-inline-block col-3 px-1 px-xl-2 col-xl-2"
										>
											<Link to={`${currentPath}${item}`}>
												<RecipeItemBorder
													ref={(el) => (itemsRef.current[i] = el)}
													isActive={url
														.toLowerCase()
														.includes(item.toLowerCase())}
													isComplete={MAP[item.toLowerCase()]}
													isIncomplete={!MAP[item.toLowerCase()]}
												>
													<h6>{item}</h6>
												</RecipeItemBorder>
											</Link>{" "}
											<Selection>
												{MAP[item.toLowerCase()] &&
													MAP[item.toLowerCase()].label}
											</Selection>
										</motion.div>
									)
							)}
						{showButton && <CreateButton />}
					</div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default RecipeCards;
