import React, { useState } from "react";
import Card from "./Card";
import { motion, AnimatePresence } from "framer-motion";

const FullPageCards = ({
	list,
	nextStep,
	onClickHandler,
	selection,
	styles,
}) => {
	const [selectedCard, setSelectedCard] = useState(selection || null);
	return (
		<div className="row align-items-md-stretch pt-4">
			<AnimatePresence>
				{list &&
					list.map((item, i) => (
						<motion.div
							key={i}
							initial={{ opacity: 0, scale: 0 }}
							animate={{ opacity: 1, scale: 1 }}
							className="col-6 col-md-3 mb-4"
						>
							<Card
								data={item}
								onClickHandler={(type) => onClickHandler(type)}
								isCardSelected={item.label === selectedCard}
								setSelectedCard={setSelectedCard}
								isFullPageLayout
								nextStep={nextStep}
								styles
							/>
						</motion.div>
					))}
			</AnimatePresence>
		</div>
	);
};

export default FullPageCards;
