import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetSelections } from "../../redux/builtRecipe";
import { Switch, Route, useLocation } from "react-router-dom";

import Recipe from "./Recipe";
import Style from "./Style";
import Suggestions from "./Suggestions";
import Confirmation from "../Confirmation";
import Form from "../Form";
import Ingredients from "./Ingredients";
import Aroma from "./Aroma";
import ABV from "./ABV";
import Flavour from "./Flavour";
import Colour from "./Colour";

const RecipeSuggestions = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		if (location.pathname === "/") dispatch(resetSelections());
	}, [dispatch, location]);
	return (
		<>
			<Switch>
				<Route exact path="/recipe-suggestions/style">
					<Style />
				</Route>
				<Route exact path="/recipe-suggestions/suggestions">
					<Suggestions />
				</Route>
				<Route exact path="/recipe-suggestions/recipe">
					<Recipe />
				</Route>
				<Route exact path="/recipe-suggestions/flavour">
					<Flavour />
				</Route>
				<Route exact path="/recipe-suggestions/abv">
					<ABV />
				</Route>
				<Route exact path="/recipe-suggestions/aroma">
					<Aroma />
				</Route>
				<Route exact path="/recipe-suggestions/colour">
					<Colour />
				</Route>
				<Route exact path="/recipe-suggestions/overview">
					<Confirmation />
				</Route>
				<Route exact path="/recipe-suggestions/ingredients">
					<Ingredients />
				</Route>
				<Route exact path="/recipe-designer/user-details">
					<Form />
				</Route>
			</Switch>
		</>
	);
};

export default RecipeSuggestions;

export { Recipe, Style };
