import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components/header/Header";
import LandingPage from "./routes/LandingPage";
import RecipeDesigner from "./routes/recipeDesigner";
import RecipeSuggestions from "./routes/recipeSuggestions";
import FAQ from "./routes/FAQ";
import Form from "./routes/Form";
import LoginForm from "./components/loginForm/LoginForm";
import ForgottenPassword from "./components/loginForm/ForgottenPassword";
import Modal from "./components/common/Modal";
import {
	toggleLoginForm,
	isModalOpenSelector,
	isShowPasswordReset,
	isShowSaveRecipe,
	isShowLoginForm,
	isShowSignUpForm,
} from "./redux/content";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import SaveRecipe from "./components/saveRecipe/SaveRecipe";
import IngredientsList from "./routes/IngredientsList";
import IngredientsDetails from "./routes/IngredientsDetails";
import "react-toastify/dist/ReactToastify.css";
import { selectIsSignedIn } from "./redux/user";
import SavedRecipes from "./routes/SavedRecipes";

function App() {
	const dispatch = useDispatch();
	const isModalOpen = useSelector(isModalOpenSelector);
	const showPasswordReset = useSelector(isShowPasswordReset);
	const showSaveRecipe = useSelector(isShowSaveRecipe);
	const showLoginForm = useSelector(isShowLoginForm);
	const isSignedIn = useSelector(selectIsSignedIn);
	const showSignUpForm = useSelector(isShowSignUpForm);
	const onLoginButtonClick = () => {
		dispatch(toggleLoginForm());
	};
	return (
		<>
			<Router>
				<Header />

				<Switch>
					<Route path="/recipe-designer">
						<RecipeDesigner />
					</Route>
					<Route path="/recipe-suggestions">
						<RecipeSuggestions />
					</Route>
					<Route path="/our-ingredients/:ingredient">
						<IngredientsDetails />
					</Route>
					<Route path="/our-ingredients">
						<IngredientsList />
					</Route>
					<Route path="/faq">
						<FAQ />
					</Route>
					<Route path="/user-details">
						<Form />
					</Route>
					{isSignedIn && (
						<Route path="/saved-recipes">
							<SavedRecipes />
						</Route>
					)}
					<Route path="/">
						<LandingPage />
					</Route>
				</Switch>
				<Modal isOpen={isModalOpen} setModalOpen={onLoginButtonClick}>
					{showLoginForm && <LoginForm />}
					{showPasswordReset && <ForgottenPassword />}
					{showSaveRecipe && <SaveRecipe />}
					{showSignUpForm && <Form />}
				</Modal>
			</Router>
			<ToastContainer position="bottom-center" />

			<div className="patterned-footer"></div>
		</>
	);
}

export default App;
