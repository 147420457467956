import { request } from "../../app/axios";
import { baseUmbracoUrl } from "../../app/axios";

export const fetchColours = async () => {
	let data = await request("colours");
	data = data.items.filter((d) => d);
	data = data.map(({ images = "", ...item }) => ({
		...item,
		img: baseUmbracoUrl + ((images && images[0]) || ""),
		isBackgroundImage: true,
	}));
	return data;
};
