import React, { useState, useEffect } from "react";
import StyledSelect from "../inputs/StyledSelect";
import { baseUmbracoUrl } from "../../app/axios";

const IngredientsList = ({
	ingredients,
	type,
	onChangeHandler,
	selectedIngredient,
	error,
	text,
	selectedVolume,
	numberOfSachets,
}) => {
	console.log(
		ingredients,
		type,
		onChangeHandler,
		selectedIngredient,
		error,
		text,
		selectedVolume,
		numberOfSachets
	);

	const orderIngredients = (ingredientsArray) => {
		console.log(ingredientsArray);
		let orderedIngredients = [];
		const premium = ingredientsArray.filter((ing) =>
			ing?.productTypeFlag?.includes("Premium")
		);
		const standard = ingredientsArray.filter((ing) =>
			ing?.productTypeFlag?.includes("Standard")
		);
		const rest = ingredientsArray.filter(
			(ing) =>
				ing?.productTypeFlag.length == 0 ||
				typeof ing?.productTypeFlag == "undefined"
		);

		orderedIngredients = [...premium, ...standard, ...rest];
		console.log(orderedIngredients);
		return orderedIngredients;
	};

	const ingredientCount = selectedIngredient
		? selectedIngredient.reduce(
				(acc, curr) => parseInt(acc) + parseInt(curr.value),
				0
		  )
		: 0;
	const volumeMap = numberOfSachets;
	return ingredients &&
		type &&
		onChangeHandler &&
		selectedIngredient &&
		text &&
		selectedVolume &&
		numberOfSachets ? (
		<div className="recipe-option-area row">
			<div className="row">
				<div className="col-12">
					<h3 className="mb-3">
						Choose your {type && type.toLowerCase()}
						{selectedVolume &&
							volumeMap &&
							` - Please select ${volumeMap[selectedVolume.label]} ${
								type === "Hops" ? " 30g sachet(s)" : ""
							}`}
					</h3>
					<p>{text}</p>
					{error && <p className="error">{error}</p>}
					{selectedIngredient &&
						selectedVolume &&
						ingredientCount > volumeMap[selectedVolume.label] && (
							<p className="error">
								Please remove &nbsp;
								{ingredientCount - volumeMap[selectedVolume.label]} {type}
								{type === "Extract" ? "s" : ""}
							</p>
						)}
				</div>
			</div>
			{ingredients &&
				orderIngredients(ingredients).map(
					({ img, label, description, id, colours }, i) => (
						<IngredientsItem
							key={id}
							img={img}
							label={label}
							description={description}
							id={id}
							colours={colours}
							ingredients={ingredients}
							type={type}
							onChangeHandler={onChangeHandler}
							selectedIngredient={selectedIngredient}
							error={error}
							text={text}
							selectedVolume={selectedVolume}
							numberOfSachets={numberOfSachets}
							volumeMap={volumeMap}
							ingredientCount={ingredientCount}
						/>
					)
				)}
		</div>
	) : null;
};

export default IngredientsList;

export const IngredientsItem = ({
	img,
	label,
	description,
	id,
	colours,

	onChangeHandler,
	selectedIngredient,
	ingredientCount,
	selectedVolume,
	volumeMap,
}) => {
	const [value, setValue] = useState({ value: 0, label: 0 });
	useEffect(() => {
		if (selectedIngredient) {
			const selected = selectedIngredient.find(
				(ingredient) => ingredient.id === id
			);
			if (selected) {
				setValue({ label: selected.value, value: selected.value });
			} else {
				setValue({ label: 0, value: 0 });
			}
		}
	}, [selectedIngredient.length, selectedIngredient, id]);
	const selectOptions = Array.from(
		[...Array(selectedVolume ? volumeMap[selectedVolume.label] + 1 : 2).keys()],
		(value) => ({
			value: value,
			label: value,
		})
	);

	return (
		<div className="col-12 col-md-6 row recipe-option mb-4">
			<div className="col-4">
				<img className="img-fluid w-100" src={img} alt={`${label} extract`} />
			</div>
			<div className="col-8 ps-5">
				<div className="row">
					<div className="col-6">
						<h4>{label}</h4>
					</div>
					<div className="col-6">
						<div>
							<StyledSelect
								id={`ingrdient-${id}`}
								name={`ingrdient-${id}`}
								inputId={`ingrdient-${id}`}
								isSearchable={false}
								onChange={(val) => {
									onChangeHandler({ label, img, id, value: val.value });
								}}
								options={selectOptions}
								padding={0}
								value={value}
							/>
						</div>
					</div>
					<div className="row mt-3">
						<p className="font-weight-bold">{description}</p>
						<div className="col-3">
							{colours && <p className="mb-0">Colour range: </p>}
						</div>
						<div className="d-flex col-9 flex-wrap justify-content-start align-items-center">
							{colours &&
								colours.map((colour) => (
									<img
										alt={`Colour of beer - ${colour.id}`}
										key={colour.id}
										style={{
											height: "3rem",
											width: "3rem",
											marginLeft: "2rem",
										}}
										src={baseUmbracoUrl + colour.images[0]}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
