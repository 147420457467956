import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { selectSelectedStyle } from "../../redux/builtRecipe";

const navigationMap = [
	{
		name: "Flavour",
		count: 1,
	},
	{
		name: "Aroma",
		count: 2,
	},
	{
		name: "Colour",
		count: 3,
	},

	{
		name: "ABV",
		count: 3,
	},
];

const BeerImage = () => {
	const style = useSelector(selectSelectedStyle);
	const history = useHistory();
	const currentPath = history.location.pathname.includes("/recipe-designer/")
		? "/recipe-designer/"
		: "/recipe-suggestions/";
	const pathName = history.location.pathname.replace(currentPath, "");
	const currentPage = navigationMap.filter((page) => {
		return page.name.toLowerCase() === pathName.toLowerCase();
	})[0];
	const { name, count } = currentPage;
	return (
		<>
			<div className="d-xl-flex flex-column col-12 col-xl-5 justify-content-cente align-items-center pt-4 mb-0 mb-xl-5">
				<div className="col-4 col-lg-4 col-xl-9 offset-4 offset-lg-4 offset-xl-0 ">
					<img className="img-fluid w-100" src={style.img} alt="img of beer" />
				</div>
				<div className="col-12 d-block d-xl-none text-center pt-4">
					<h5>
						<strong>
							{count} / 3 Select your {name}
						</strong>
					</h5>
				</div>
			</div>
		</>
	);
};

export default BeerImage;
