import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchFlavours } from "./flavourAPI";

const initialState = {
	flavours: [],
};

export const fetchFlavoursAsync = createAsyncThunk(
	"flavour/fetchFlavours",
	async () => {
		const response = await fetchFlavours();
		return response;
	}
);

export const flavourSlice = createSlice({
	name: "flavour",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchFlavoursAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchFlavoursAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.flavours = action.payload;
			});
	},
});

export const selectFlavours = (state) => state.flavour.flavours;
export const selectFilteredExtracts = (state) =>
	state.flavour.flavours
		.filter(
			(flavour) => state.builtRecipe.selectedFlavour.label === flavour.label
		)
		.map((flavour) => flavour.extracts)[0];
// REMOVED AS COLOUR SECTION IS OBSOLETE
// .filter((extract) =>
// 	extract.colours.some(
// 		(colour) => colour.label === state.builtRecipe.selectedColour.label
// 	)
// );

export default flavourSlice.reducer;
