import React from "react";

import { useFormik } from "formik";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import { StyledLabel, Label, StyledTextField } from "../loginForm/LoginInputs";
import {
	selectFullRecipeForSaving,
	setShowReview,
	setRecipeName,
	setRecipeId,
} from "../../redux/builtRecipe";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { selectActionText, toggleShowSaveRecipe } from "../../redux/content";
import { saveRecipe } from "../../app/axios";
import { toast } from "react-toastify";
import Toast from "../toast/Toast";

const validationSchema = yup.object({
	name: yup.string("Enter a recipe name").required("Recipe Name is required"),
});

const SaveRecipe = () => {
	const dispatch = useDispatch();
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const recipe = useSelector(selectFullRecipeForSaving);
	const text = useSelector(selectActionText);

	const formik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: validationSchema,
		onSubmit: async ({ name }) => {
			try {
				console.log(recipe);
				const response = await saveRecipe({
					...recipe,
					recipeName: name,
				});

				if (response) {
					toast(<Toast messageKey="recipeSavedSuccess" />);
					dispatch(setRecipeId(response));
				}

				dispatch(setRecipeName(name));
				dispatch(setShowReview(true));
				dispatch(toggleShowSaveRecipe());
			} catch (e) {
				console.warn(e);
			}

			window.scrollTo(0, 0);
		},
	});

	return (
		<div className="container-lg" data-testid="login-modal">
			<div className="row">
				<form onSubmit={formik.handleSubmit}>
					{/* {text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ms-4">
								{" "}
								<h1>{text.form}</h1>
							</div>
						</div>
					)} */}

					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro text-center">
							<div className="mb-4 ms-4">
								{" "}
								<h3>{`Give your ${selectedStyle.label} a name`}</h3>
							</div>
						</div>
					)}
					<div className="row justify-content-center py-4 mb-5 d-flex">
						<div className="col-6 col-lg-2">
							<img
								alt="alt text"
								className="img-fluid w-100"
								src={selectedStyle.img || ""}
							/>
						</div>
					</div>

					<div
						className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group"
						data-testid="login-email"
					>
						<StyledLabel
							control={
								<StyledTextField
									id="name"
									name="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your name here...."
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Name your recipe</Label>}
							labelPlacement="top"
						/>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-4 mt-5">
						<button type="submit" className="btn btn-secondary w-100">
							Confirm and save recipe
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SaveRecipe;
