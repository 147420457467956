import React, { useRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import TransparentImage from "../../assets/img/transparent_img.svg";
import { useSelector, useDispatch } from "react-redux";
import { builtRecipeSelectors, setSachets } from "../../redux/builtRecipe";
import { fetchStylesAsync, selectStyles } from "../../redux/style";
import { fetchAromasAsync } from "../../redux/aroma";
// import useWindowDimensions from "../common/useWindowDimensions";
import { useLocation } from "react-router";

const CardWrapper = styled.div`
	position: relative;
	max-height: 50%;

	.selection-card-small {
		background: ${({ bgColour, bgImage }) =>
			bgImage ? `url(${bgImage})` : `#${bgColour}`};
		background-repeat: no-repeat;
		background-size: cover;
	}

	.selected-icon {
		border-radius: 50%;
		background: #b3bc35;
		display: grid;
		place-items: center;
		padding: 0.5rem;
		position: absolute;
		top: -5px;
		right: 10px;
	}
`;

const itemVariants = {
	visible: { opacity: 1, y: 0 },
	hidden: { opacity: 0, y: -500 },
	transition: {},
};

const Card = ({
	data,
	nextStep,
	onClickHandler,
	isCardSelected,
	setSelectedCard,
	isFullPageLayout,
	isConfirmationPage,
	mobile,
	isRecipePage,
	styles,
}) => {
	const cardRef = useRef(null);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const recipeItems = useSelector(builtRecipeSelectors.recipe);
	const style = useSelector(builtRecipeSelectors.style);
	const allStyles = useSelector(selectStyles);
	const recipeItemsCheck = recipeItems.every((item) => item);
	const {
		label,
		img,
		cardColourRGB: colour,
		to,
		type,
		isBackgroundImage,
		id,
		description,
		numberOfSachets,
	} = {
		...data,
	};
	//const { width } = useWindowDimensions();
	const onClick = () => {
		// console.log(cardRef.current.getBoundingClientRect().left);

		// cardRef &&
		// 	cardRef.current &&
		// 	width < 1200 &&
		// 	window.scrollTo({
		// 		left: cardRef.current.getBoundingClientRect().left,
		// 		behavior: "smooth",
		// 	});
		onClickHandler &&
			onClickHandler({
				label,
				img,
				cardColourRGB: colour,
				isBackgroundImage,
				id,
				description,
				numberOfSachets,
			});
		setSelectedCard(label);

		if (nextStep && !recipeItemsCheck) {
			history.push({
				pathname: nextStep,
				state: { prevPath: location.pathname },
			});
		}
	};

	return (
		<>
			{isFullPageLayout && (
				<CardWrapper bgColour={colour || "fff"}>
					<div
						ref={cardRef}
						onClick={() => onClick()}
						className={`h-100 p-4 p-md-5 shadow selection-card-small ${
							styles && "selection-card-small-styles"
						}`}
					>
						<div className="row align-items-center justify-content-center">
							{img && (
								<div className="col-12  text-center mb-4">
									<img
										className="img-fluid w-100"
										src={img}
										alt="img of selectable variable"
									/>
								</div>
							)}
							{type !== "ABV" && type !== "abv" && (
								<div className="col-12 text-center">
									<h2 className="m-0">{label}</h2>
								</div>
							)}
						</div>

						{isCardSelected && (
							<motion.div
								key={label}
								initial={{ opacity: 0, scale: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0, scale: 0 }}
								className="selected-icon"
							>
								<i className="fas fa-check text-white"></i>
							</motion.div>
						)}
					</div>
				</CardWrapper>
			)}

			{isConfirmationPage && !mobile && isRecipePage && (
				<CardWrapper
					as={motion.div}
					key={`${label}-card-large`}
					variants={itemVariants}
					bgColour={colour || "fff"}
					className="col-3 mb-5"
					bgImage={isBackgroundImage && img}
				>
					<h3 className="mb-3">{type}</h3>
					<div className={`h-100 p-0 p-md-3 shadow selection-card-small`}>
						<div className="row align-items-center justify-content-center">
							<div className="col-12 col-md-6 text-center mb-2">
								{type === "ABV" || type === "abv" ? (
									<h5 className="">{label}%</h5>
								) : (
									<img
										className={`img-fluid w-100 ${
											isBackgroundImage ? "opacity-0" : null
										}`}
										src={isBackgroundImage ? TransparentImage : img || ""}
										alt="img of selectable variable"
									/>
								)}
							</div>

							{type !== "ABV" && type !== "abv" && (
								<div className="col-12 text-center">
									<h2 className="m-0">{label}</h2>
								</div>
							)}
						</div>
						{to && type !== "Colour" && (
							<Link
								className="edit-icon edit-icon-card"
								onClick={async () => {
									console.log("STYLE", style);
									if (location.pathname.includes("recipe")) {
										try {
											const stylePromise = await dispatch(
												fetchStylesAsync()
											).unwrap();
											const aromaPromise = await dispatch(
												fetchAromasAsync()
											).unwrap();
											dispatch(setSachets({ style, allStyles }));
											// handle result here
										} catch (e) {
											console.warn(e);
										}
									}
									// fetch Styles and then set numbers of saceths from style name
									// fetch flavours/aroma/abv from stlen ame
								}}
								to={to}
							>
								<i className="fas fa-pencil-alt"></i>
							</Link>
						)}
					</div>
					<AnimatePresence exitBeforeEnter>
						{isCardSelected && (
							<motion.div
								key={label}
								variants={itemVariants}
								className="selected-icon"
							>
								<i className="fas fa-check text-white"></i>
							</motion.div>
						)}
					</AnimatePresence>
				</CardWrapper>
			)}

			{isConfirmationPage && mobile && (
				<CardWrapper
					as={motion.div}
					key={`${label}-card-large`}
					initial={{ opacity: 0, scale: 0 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 0 }}
					bgColour={colour || "fff"}
					bgImage={isBackgroundImage && img}
					className="col-4 col-4__custom-card flex-grow-1 align-self-stretch col-xl-3 mb-3"
				>
					<h3>{type}</h3>

					<div
						ref={cardRef}
						onClick={() => onClick()}
						className={`${
							type === "abv" ? "h-100" : null
						} p-1 py-3 p-md-3 shadow selection-card-small h-100`}
					>
						<div className="row align-items-center justify-content-center">
							<div className="col-10 col-sm-8 col-md-6 col-xl-8 text-center mb-4  px-md-1">
								{type === "ABV" || type === "abv" ? (
									<h5 className="mt-4">{label}%</h5>
								) : (
									<img
										className={`img-fluid w-100 ${
											isBackgroundImage ? "opacity-0" : null
										}`}
										src={isBackgroundImage ? TransparentImage : img || ""}
										alt="img of selectable variable"
									/>
								)}
							</div>

							{type !== "ABV" && type !== "abv" && (
								<div className="col-12 text-center">
									<h2 className="m-0">{label}</h2>
								</div>
							)}
						</div>
						{to && type !== "Colour" && (
							<Link className="edit-icon edit-icon-card" to={to}>
								<i className="fas fa-pencil-alt"></i>
							</Link>
						)}
					</div>
					<AnimatePresence exitBeforeEnter>
						{isCardSelected && (
							<motion.div
								key={label}
								initial={{ opacity: 0, scale: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0, scale: 0 }}
								className="selected-icon"
							>
								<i className="fas fa-check text-white"></i>
							</motion.div>
						)}
					</AnimatePresence>
				</CardWrapper>
			)}

			{isConfirmationPage && !mobile && !isRecipePage && (
				<CardWrapper
					as={motion.div}
					key={`${label}-card-large`}
					variants={itemVariants}
					bgColour={colour || "fff"}
					className="col-6 mb-5 mt-2"
					bgImage={isBackgroundImage && img}
				>
					<h3>{type}</h3>
					<div className={`h-100 p-0 p-md-3  shadow selection-card-small`}>
						<div className="row align-items-center justify-content-center">
							<div className="col-12 col-md-8 text-center mb-2">
								{type === "ABV" || type === "abv" ? (
									<h5 className="mt-2">{label}%</h5>
								) : (
									<img
										className={`img-fluid w-100 ${
											isBackgroundImage ? "opacity-0" : null
										}`}
										src={isBackgroundImage ? TransparentImage : img || ""}
										alt="img of selectable variable"
									/>
								)}
							</div>
							{type !== "ABV" && type !== "abv" && (
								<div className="col-12 text-center">
									<h2 className="m-0">{label}</h2>
								</div>
							)}
						</div>
						{to && (
							<Link className="edit-icon edit-icon-card" to={to}>
								<i className="fas fa-pencil-alt"></i>
							</Link>
						)}
					</div>
					<AnimatePresence exitBeforeEnter>
						{isCardSelected && (
							<motion.div
								key={label}
								variants={itemVariants}
								className="selected-icon"
							>
								<i className="fas fa-check text-white"></i>
							</motion.div>
						)}
					</AnimatePresence>
				</CardWrapper>
			)}

			{!isFullPageLayout && !isConfirmationPage && (
				<CardWrapper
					as={motion.div}
					key={`${label}-card-large`}
					initial={{ opacity: 0, scale: 0 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 0 }}
					bgColour={colour || "fff"}
					bgImage={isBackgroundImage && img}
					className="col-4 col-4__custom-card col-xl-3 mb-3"
				>
					<div
						ref={cardRef}
						onClick={() => onClick()}
						className={`h-100 p-1 py-3 p-md-3 shadow selection-card-small`}
					>
						<div className="row align-items-center justify-content-center">
							<div className="col-10 col-sm-8 col-md-6 col-xl-8 text-center mb-4  px-md-1 position-relative">
								{type === "ABV" || type === "abv" ? (
									<>
										{" "}
										<img
											className={`img-fluid w-100 opacity-0`}
											src={TransparentImage}
											alt="img of selectable variable"
										/>
										<h5 className="mt-4 position-absolute card-fix--no-image">
											{label}%
										</h5>
									</>
								) : (
									<img
										className={`img-fluid w-100 ${
											isBackgroundImage ? "opacity-0" : null
										}`}
										src={isBackgroundImage ? TransparentImage : img || ""}
										alt="img of selectable variable"
									/>
								)}
							</div>

							{type === "ABV" || type === "abv" ? (
								<div className="col-12 text-center opacity-0">
									<h2 className="m-0">{label}</h2>
								</div>
							) : (
								<div className="col-12 text-center ">
									<h2 className="m-0 ">{label}</h2>
								</div>
							)}
						</div>
					</div>
					<AnimatePresence exitBeforeEnter>
						{isCardSelected && (
							<motion.div
								key={label}
								initial={{ opacity: 0, scale: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0, scale: 0 }}
								className="selected-icon"
							>
								<i className="fas fa-check text-white"></i>
							</motion.div>
						)}
					</AnimatePresence>
				</CardWrapper>
			)}
		</>
	);
};

export default Card;
