import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
	open: {
		transition: { staggerChildren: 0.07, delayChildren: 0.2 },
		display: "block",
	},
	closed: {
		transition: { staggerChildren: 0.05, staggerDirection: -1 },
		display: "none",
	},
};

export const Navigation = ({ toggle }) => (
	<motion.ul variants={variants}>
		{itemIds.map((item, i) => (
			<MenuItem i={item} key={i} toggle={toggle} />
		))}
	</motion.ul>
);

const itemIds = [
	{ name: "Recipe Designer", path: "/recipe-designer/style" },
	{ name: "Recipe Suggestion", path: "/recipe-suggestions/style" },
	{ name: "Our Ingredients", path: "/our-ingredients" },
	{ name: "FAQs", path: "/faq" },
	{ name: "Exit App", path: "https://www.muntons.com/home-brewing-supplies/" },
];
