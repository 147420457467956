import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	fetchIngredientsAsync,
	selectIngredients,
	setSelectedIngredient,
} from "../redux/ingredients";
import { baseUmbracoUrl } from "../app/axios";

const IngredientsList = () => {
	const ingredients = useSelector(selectIngredients);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchIngredientsAsync());
	}, [dispatch]);
	console.log(ingredients);
	return (
		<main className="container-xxl">
			<div className="text-center pt-5">
				<h2 className="mb-3">Our Ingredients Range</h2>
			</div>

			<div className="recipe-option-area row">
				{ingredients &&
					ingredients.length &&
					ingredients.map(({ label, id, description, colours, images }, i) => (
						<div key={i} className="col-12 col-md-6 recipe-option mb-4 row">
							<div className="col-4">
								{images && images[0] && (
									<img
										alt="extract"
										className="img-fluid w-100"
										src={baseUmbracoUrl + images[0]}
									/>
								)}
							</div>
							<div className="col-8 ps-5">
								<div className="row">
									<div className="col-8">
										<h4>{label}</h4>
									</div>

									<div className="col-12 mt-3">
										<p>{description}</p>
										<Link
											to={`/our-ingredients/${label.replaceAll(" ", "_")}`}
											onClick={() => dispatch(setSelectedIngredient(id))}
											className="btn btn-secondary px-4"
										>
											View Ingredient Details
										</Link>
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</main>
	);
};

export default IngredientsList;
