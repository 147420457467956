import React from "react";
import styled from "styled-components";
import toastMessages from "./messages";

const CloseToast = styled.p`
	&&& {
		color: #b3bc35 !important;
		text-decoration: underline;
		cursor: pointer;
	}
`;

const ToastWrapper = styled.div``;

const Toast = ({ closeToast, header, body, closeText, messageKey }) => {
	return (
		<ToastWrapper>
			<p className="fw-bold">{toastMessages[messageKey].header || header}</p>
			<p>{toastMessages[messageKey].body || body}</p>
			<CloseToast onClick={closeToast}>
				{toastMessages[messageKey].closeText || closeText}
			</CloseToast>
		</ToastWrapper>
	);
};

export default Toast;
