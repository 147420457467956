import React, { useEffect } from "react";
import { builtRecipeSelectors } from "../../redux/builtRecipe";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../components/tabs";
import { fetchRecipeAsync } from "../../redux/builtRecipe";

const Recipe = () => {
	const selectedStyle = useSelector(builtRecipeSelectors.style);

	const dispatch = useDispatch();
	useEffect(() => {
		window.scrollTo(0, 0);

		dispatch(fetchRecipeAsync());
	}, [dispatch]);
	return (
		<main className="container-xxl">
			<div className="row mb-2">
				<div className="col-md-12 text-center text-intro py-3">
					<h1>
						Here’s your <strong>{selectedStyle.label}</strong> recipe
					</h1>
				</div>
			</div>

			<Tabs />
		</main>
	);
};

export default Recipe;
