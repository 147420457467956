import { request } from "../../app/axios";
import { baseUmbracoUrl } from "../../app/axios";

export const fetchStyles = async () => {
	let data = await request("styles");
	data = data.items.filter((d) => d);

	data = data.map(
		({ images = "", yeasts, colours, abVs: abvs, baseFlavours, ...item }) => ({
			...item,
			yeasts:
				yeasts &&
				yeasts.map(({ images = "", ...yeast }) => ({
					...yeast,
					img: baseUmbracoUrl + ((images && images[0]) || ""),
					type: "yeast",
				})),
			colours:
				colours &&
				colours.map(({ images = "", ...colours }) => ({
					...colours,
					img: baseUmbracoUrl + ((images && images[0]) || ""),
					isBackgroundImage: true,
					type: "colour",
				})),
			abvs:
				abvs &&
				abvs
					.map(({ images = "", ...abv }) => ({
						...abv,
						img: baseUmbracoUrl + ((images && images[0]) || ""),
						type: "abv",
					}))
					.sort((a, b) => parseFloat(a.value) - parseFloat(b.value)),
			flavours:
				baseFlavours &&
				baseFlavours.map(({ images = "", ...baseFlavour }) => ({
					...baseFlavour,
					img: baseUmbracoUrl + ((images && images[0]) || ""),
					type: "flavour",
				})),

			img: baseUmbracoUrl + ((images && images[0]) || ""),
		})
	);
	return data;
};
