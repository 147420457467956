import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import styled from "styled-components";

const StyledNav = styled.nav`
	display: grid;
	place-items: self-start;

	.background {
		position: absolute;
		top: 0;
		left: -50%;
		bottom: 0;
		width: 200vw;
		background: #fff;
		z-index: 99;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	}

	button {
		outline: none;
		border: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		cursor: pointer;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: transparent;
		z-index: 101;
		position: relative;
		top: -5px;
		left: -15px;
	}

	button svg {
		position: absolute;
		z-index: 999999;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		z-index: 100;
	}

	ul {
		padding: 25px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	li {
		list-style: none;
		margin: 0 auto 20px;
		text-align: center;
		cursor: pointer;
		font-size: 2rem;
	}

	.icon-placeholder {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		flex: 40px 0;
		margin-right: 20px;
	}

	.text-placeholder {
		border-radius: 5px;
		width: 200px;
		height: 20px;
		flex: 1;
	}

	.refresh {
		padding: 10px;
		position: absolute;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 10px;
		width: 20px;
		height: 20px;
		top: 10px;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`;

const sidebar = {
	open: (height = 1000) => ({
		clipPath: `circle(${height * 3 + 200}px at 40px 40px)`,
		opacity: 1,
		pointerEvents: "auto",
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
	}),
	closed: {
		clipPath: "circle(30px at 40px 40px)",
		opacity: 0,
		pointerEvents: "none",
		transition: {
			delay: 0.1,
			type: "spring",
			stiffness: 400,
			damping: 40,
		},
	},
};

const Menu = () => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef(null);
	const { height } = useDimensions(containerRef);

	const toggleHander = () => {
		if (document.body.style.overflow === "hidden") {
			document.body.style.overflow = "auto";
			setTimeout(() => {
				document.querySelector(".header-container").classList.remove("h-100");
			}, 200);
		} else {
			setTimeout(() => {
				document.querySelector(".header-container").classList.add("h-100");
			}, 200);

			document.body.style.overflow = "hidden";
		}
		toggleOpen();
	};

	return (
		<StyledNav
			initial={false}
			animate={isOpen ? "open" : "closed"}
			custom={height}
			ref={containerRef}
			as={motion.nav}
		>
			<motion.div className="background" variants={sidebar} />
			<Navigation toggle={toggleHander} />
			<MenuToggle toggle={() => toggleHander()} />
		</StyledNav>
	);
};

export default Menu;
