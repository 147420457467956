import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70vw",
	bgcolor: "background.paper",
	borderRadius: "5px",
	boxShadow: 24,
	p: 4,
	maxHeight: "75vh",
	overflow: "scroll",
};

const CloseButton = styled.span`
	font-weight: bold;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
`;

export default function BasicModal({ isOpen, setModalOpen, children }) {
	const handleClose = () => setModalOpen(false);

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<CloseButton onClick={handleClose}>X</CloseButton>
					{children}
				</Box>
			</Modal>
		</div>
	);
}
