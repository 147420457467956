import Select, { components } from "react-select";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

export const customStyles = {
	menu: (provided, state) => ({
		...provided,
		zIndex: "9999",
		fontFamily: `"Raleway", sans-serif`,
	}),
	option: (provided, { isDisabled, isSelected, isFocused }) => ({
		...provided,

		backgroundColor: "transparent",
		fontWeight: isDisabled
			? null
			: isSelected
			? "700"
			: isFocused
			? "700"
			: null,
		color: "#3B1429",
	}),

	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
		padding: 10,
		border: state.isSelected ? "2px solid #3B1429" : "1px solid #3B1429",
		boxShadow: state.isSelected ? "0 0 1px #3B1429" : "0 0 1px #3B1429",
		"&:hover": {
			boxShadow: "0 0 4px #3B1429",
			boxSizing: "border-box",
		},
		fontFamily: `"Raleway", sans-serif`,
	}),

	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: "transparent",
	}),
};

export const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<span
				style={{
					color: "#3B1429",
					transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : null,
					transition: "all .2s ease",
				}}
			>
				&#9660;
			</span>
		</components.DropdownIndicator>
	);
};

const { Option } = components;
const IconOption = (props) => {
	return (
		<Option {...props} className="d-flex justify-content-between">
			{props.data.label}
			{props.isSelected && <CheckCircleOutlinedIcon htmlColor="#40102A" />}
		</Option>
	);
};

const StyledSelect = (props) => {
	return (
		<Select
			styles={customStyles}
			className={typeof props.padding !== undefined ? `select-no-padding` : ""}
			components={{ DropdownIndicator, Option: IconOption }}
			{...props}
		/>
	);
};

export default StyledSelect;
