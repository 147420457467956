import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIngredients } from "../redux/ingredients";
import { fetchSavedRecipesAsync, selectSavedRecipes } from "../redux/user";
import SavedRecipeItem from "../components/common/SavedRecipeItem";

const SavedRecipes = () => {
	const ingredients = useSelector(selectIngredients);
	const savedRecipes = useSelector(selectSavedRecipes);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchSavedRecipesAsync());
	}, [dispatch]);
	console.log(ingredients);
	const onClickHandler = () => {
		history.push("/recipe-designer/style");
	};
	return (
		<main className="container-xxl">
			<div className="text-center pt-5">
				<h2 className="mb-3">Your saved recipes</h2>
			</div>

			<div className="recipe-option-area row pt-5">
				{savedRecipes &&
					savedRecipes.length &&
					savedRecipes.map((props, i) => (
						<SavedRecipeItem {...props} fullRecipe={props} />
					))}
			</div>
		</main>
	);
};

export default SavedRecipes;
