import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAromas, fetchAromasAsync } from "../../redux/aroma";
import { selectAroma, builtRecipeSelectors } from "../../redux/builtRecipe";
import ActionText from "../../components/actionText/ActionText";
import Cards from "../../components/cards/Cards";
import BottomNav from "../../components/bottomNav/BottomNav";
import BeerImage from "../../components/beerImage/BeerImage";

const Aroma = () => {
	const aromas = useSelector(selectAromas);
	const selectedAroma = useSelector(builtRecipeSelectors.aroma);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchAromasAsync());
	}, [dispatch]);
	return (
		<>
			<main className="container-xxl">
				<ActionText page="aroma" mobile />
				<div className="row">
					<BeerImage />
					<Cards
						page="aroma"
						selection={selectedAroma}
						list={aromas}
						onClickHandler={(type) => dispatch(selectAroma(type))}
						bottomNav
						nextStep={"/recipe-designer/abv"}
					/>
				</div>
				<BottomNav mobile />
			</main>
		</>
	);
};

export default Aroma;
