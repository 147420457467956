import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectFilteredABVS } from "../../redux/style";
import { selectABV, builtRecipeSelectors } from "../../redux/builtRecipe";
import ActionText from "../../components/actionText/ActionText";
import Cards from "../../components/cards/Cards";
import BottomNav from "../../components/bottomNav/BottomNav";
import BeerImage from "../../components/beerImage/BeerImage";

const ABV = () => {
	const ABVAmounts = useSelector(selectFilteredABVS);
	const selectedABV = useSelector(builtRecipeSelectors.abv);

	const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(fetchABVAsync());
	// }, [dispatch]);
	return (
		<>
			<main className="container-xxl">
				<ActionText page="abv" mobile />
				<div className="row">
					<BeerImage />
					<Cards
						page="abv"
						selection={selectedABV}
						list={ABVAmounts}
						onClickHandler={(type) => dispatch(selectABV(type))}
						bottomNav
					/>
				</div>
				<BottomNav mobile />
			</main>
		</>
	);
};

export default ABV;
