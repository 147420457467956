import React, { useState } from "react";
import { Rating } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "../common/Modal";
import SocialShare from "../tabs/SocialShare";
import { deleteRecipeAsync } from "../../redux/user";
import { baseUmbracoUrl } from "../../app/axios";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { useDispatch } from "react-redux";
import { setSelectedSavedRecipe } from "../../redux/builtRecipe";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DateRangeIcon from "@mui/icons-material/DateRange";

const Button = styled.button`
	text-decoration: underline;
	cursor: pointer;
	margin-right: 2rem;
	text-align: left;
	white-space: nowrap;
	&:hover {
		font-weight: bold;
	}

	@media (max-width: 992px) {
		margin-bottom: 1rem;
	}
`;

const ShareButton = styled.div`
	background: #d55d35;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	margin: 1rem;
	display: grid;
	place-items: center;
	cursor: pointer;
	margin-bottom: 0.5rem;
`;

const ShareTagline = styled.p`
	&&& {
		color: #d55d35 !important;
	}
`;

const SavedItemContainer = styled.div`
	position: relative;

	&:after {
		position: absolute;
		content: "";
		border-bottom: 1px solid #522b44;
		width: 80%;
		transform: translateX(-50%);
		bottom: 0;
		left: 50%;
	}
`;

const DateCreated = styled.p`
	&& {
		font-size: 0.8rem;
		margin-left: 0.5rem;
		margin-bottom: 0;
	}
`;

const SavedRecipeItem = (
	{ recipeName, label, recipeRating, id, images, fullRecipe, createdDate },
	i
) => {
	const dispatch = useDispatch();
	const [isModalOpen, setModalOpen] = useState(false);
	const rating = recipeRating ? parseInt(recipeRating) : 0;
	const getFormattedDate = (date) => {
		const d = new Date(date);
		if (d) {
			var dd = String(d.getDate()).padStart(2, "0");
			var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
			var yyyy = d.getFullYear();

			return mm + "/" + dd + "/" + yyyy;
		} else {
			return "dd/mm/yyyy";
		}
	};
	return (
		<SavedItemContainer
			key={i}
			className="col-12 col-md-6 recipe-option mb-4 row"
		>
			<div className="col-4">
				<img
					className="img-fluid w-100"
					src={baseUmbracoUrl + images}
					alt=" drink"
				/>

				<div className="d-flex justify-content-center flex-column align-items-center mt-2">
					<ShareButton>
						{" "}
						<ShareOutlinedIcon
							onClick={() => setModalOpen(true)}
							htmlColor="#fff"
						/>
					</ShareButton>
					<ShareTagline>SHARE</ShareTagline>
				</div>
			</div>
			<div className="col-8 ps-5">
				<div className="row">
					<div className="col-8">
						<h4>{recipeName}</h4>

						<Typography>Your rating</Typography>
						<Rating
							id="rating"
							name="rating"
							value={rating}
							onChange={(event, val) => {
								console.log("rating", val);
							}}
							disabled
							fullWidth // eslint-disable-line
							margin="normal"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<div className="d-flex align-items-center">
							<DateRangeIcon />{" "}
							<DateCreated>Created {getFormattedDate(createdDate)}</DateCreated>
						</div>
					</div>

					<div className="col-12 mt-3 d-flex flex-column flex-lg-row">
						<Link
							to={`/recipe-suggestions/recipe`}
							onClick={() => dispatch(setSelectedSavedRecipe(fullRecipe))}
						>
							<Button className="button-reset">
								<VisibilityIcon /> View Recipe &gt;
							</Button>
						</Link>
						<Button
							className="button-reset"
							onClick={() => dispatch(deleteRecipeAsync(id))}
						>
							<CloseIcon /> Delete
						</Button>
					</div>
				</div>
			</div>
			<Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
				<SocialShare id={id} beerStyle={label} />
			</Modal>
		</SavedItemContainer>
	);
};

export default SavedRecipeItem;
