const toastMessages = {
	recipeSavedSuccess: {
		header: "Your recipe is saved!",
		body: "",
		closeText: "Okay great thanks >",
	},
	recipeSavedError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	loginSuccess: {
		header: "Login Success!",
		body: "",
		closeText: "Okay great thanks >",
	},
	loginError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	logoutSuccess: {
		header: "Logout Success!",
		body: "",
		closeText: "Okay great thanks >",
	},
	deleteRecipeSuccess: {
		header: "Your recipe is deleted!",
		body: "",
		closeText: "Okay great thanks >",
	},
	deleteRecipeError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	forgottenPassword: {
		header: "Your password reset email has been sent",
		body: "You can reset your password using the link we emailed you",
		closeText: "Okay great thanks >",
	},
	reviewSuccess: {
		header: "Your review updated",
		body: "",
		closeText: "Okay great thanks >",
	},
	reviewError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	getSavedRecipesError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	error: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	signUpSuccess: {
		header: "You have signed up successfully, and are now logged in.",
		body: "",
		closeText: "Okay great thanks >",
	},
	signUpError: {
		header:
			"Please try again later. If this error continues please contact marketing@muntons.com",
		body: "",
		closeText: "Okay great thanks >",
	},
	incorrectCredentials: {
		header: "Incorrect username or password",
		body: "",
		closeText: "Okay great thanks >",
	},
};

export default toastMessages;
