import { request, baseUmbracoUrl } from "../../app/axios";

export const fetchABV = async () => {
	let data = await request("abvs");
	data = data.items.filter((d) => d);
	data = data.map(({ images = "", ...item }) => ({
		...item,
		img: baseUmbracoUrl + ((images && images[0]) || ""),
	}));
	return data;
};
