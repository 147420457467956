import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Rating } from "@mui/material";

export const StyledTextField = withStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
		marginLeft: "0 !important",
		marginRight: "0 !important",
		fontFamily: `"Raleway", sans-serif`,

		"& fieldset": {
			backgroundColor: "#fff",
		},
		"& input": {
			position: "relative",
			zIndex: "1000",
		},

		"& label.Mui-focused": {
			color: "#3B1429",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#3B1429",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#3B1429",
			},
			"&:hover fieldset": {
				borderColor: "#3B1429",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#3B1429",
			},
		},
		"& .MuiOutlinedInput-inputMultiline": {
			zIndex: "1000",
		},
	},
}))(TextField);

export const StyledDatePicker = withStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
		marginLeft: "0 !important",
		marginRight: "0 !important",
		"& fieldset": {
			backgroundColor: "#fff",
		},
		"& input": {
			position: "relative",
			zIndex: "1000",
		},
		"& .MuiIconButton-root": {
			position: "relative",
			zIndex: "1000",
		},
		"& .MuiInputAdornment-positionEnd": {
			position: "relative",
			zIndex: "1000",
		},

		fontFamily: `"Raleway", sans-serif`,
		"& label.Mui-focused": {
			color: "#3B1429",
		},
		"& .MuiTypography-colorPrimary": {
			color: "#3B1429",
		},
		"& ..MuiPickersYear-root": {
			color: "#3B1429",
		},

		"& .MuiInput-underline:after": {
			borderBottomColor: "#3B1429",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#3B1429",
			},
			"&:hover fieldset": {
				borderColor: "#3B1429",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#3B1429",
			},
		},
	},
}))(KeyboardDatePicker);

export const StyledLabel = withStyles((theme) => ({
	root: {
		width: "100%",
		alignItems: "start",
		marginLeft: 0,
		marginRight: 0,
		"& MuiFormControlLabel-labels": {
			marginLeft: "1rem",
		},
		"& .MuiFormControlLabel-labelPlacementTop": {},
	},
}))(FormControlLabel);

export const StyledCheckbox = withStyles((theme) => ({
	root: {
		"& svg": {
			backgroundColor: "rgba(59, 20, 41, 0.04)",
			fill: "#3B1429",
		},
		"&:hover svg": {
			backgroundColor: "rgba(59, 20, 41, 0.04)",
			fill: "#3B1429",
		},
	},
	checked: {
		color: "#3B1429",
		"&:hover": {
			backgroundColor: "rgba(59, 20, 41, 0.04) !important",
			fill: "#3B1429",
		},
	},
	colorSecondary: {
		color: "#3B1429 !important",
		"&:hover": {
			backgroundColor: "rgba(59, 20, 41, 0.04)",
			fill: "#3B1429",
		},
		"&$checked": {
			backgroundColor: "rgba(59, 20, 41, 0.04)",
			fill: "#3B1429",
		},
	},
}))(Checkbox);

export const Label = withStyles((theme) => ({
	root: {
		marginLeft: "0",
		fontWeight: "700",
	},
}))(Typography);

export const StyledRating = withStyles(() => ({
	root: {
		color: "#EAAA21 !important",
	},
	disabled: {
		color: "#EAAA21 !important",
	},
}))(Rating);
