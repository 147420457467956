import { request, baseUmbracoUrl } from "../../app/axios";

export const fetchAromas = async () => {
	let data = await request("aromas");
	data = data.items.filter((d) => d);
	data = data.map(({ images = "", hops, ...item }) => ({
		...item,
		hops:
			hops &&
			hops.map(({ images = "", ...hop }) => ({
				...hop,
				img: baseUmbracoUrl + ((images && images[0]) || ""),
			})),
		img: baseUmbracoUrl + ((images && images[0]) || ""),
	}));
	return data;
};
