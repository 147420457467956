import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { StyledLabel, Label, StyledTextField } from "./LoginInputs";
import { formLoginPost } from "../../app/axios";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styled, { css } from "styled-components";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
	toggleLoginForm,
	toggleShowPasswordReset,
	selectActionText,
	toggleShowSignUpForm,
} from "../../redux/content";
import { setIsSignedIn } from "../../redux/user";
import { toast } from "react-toastify";

import Toast from "../toast/Toast";

const SignUpSpan = styled.span`
	text-decoration: underline;
	text-transform: uppercase;
	cursor: pointer;
	margin-left: 1rem;
	${(props) =>
		props.bold &&
		css`
			font-weight: bold;
		`}
`;

const ForgottenPassword = styled.p`
	cursor: pointer;
`;

const ErrorContainer = styled.div`
	background: rgba(240, 52, 52, 0.3);
	border: 2px solid red;
	border-radius: 5px;
	margin-left: 20px;
	margin-right: 20px;
	width: 100%;
	text-align: center;
	p {
		margin-top: 1rem;
	}
`;

const validationSchema = yup.object({
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	password: yup.string().required("Required"),
});

const LoginForm = () => {
	const dispatch = useDispatch();
	const [showSkip, setShowSkip] = useState(false);
	const location = useLocation();
	useEffect(() => {
		if (
			location.pathname === "/recipe-suggestions/ingredients" ||
			location.pathname === "/recipe-designer/ingredients"
		)
			setShowSkip(true);
	}, [location.pathname]);

	const [showPassword, setShowPassword] = useState(false);
	const handleMouseUpPassword = () => setShowPassword(false);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);
	const text = useSelector(selectActionText);
	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			grant_type: "password",
		},
		validationSchema: validationSchema,
		validator: () => ({}),
		onSubmit: async (values, { setStatus, resetForm }) => {
			// rename email to username in values object for API
			// eslint-disable-next-line
			delete Object.assign(values, { ["username"]: values["email"] })["email"];
			setStatus();
			try {
				const response = await formLoginPost(values);
				const status = response.status;
				if (status === 200) {
					const { access_token, refresh_token } = response.data;
					if (access_token) {
						localStorage.setItem("access_token", access_token);
						localStorage.setItem("refresh_token", refresh_token);
						if (location.pathname === "/saved-recipes") {
							history.push("/");
						}
						toast(<Toast messageKey={"loginSuccess"} />);
						dispatch(setIsSignedIn());
						dispatch(toggleLoginForm());
						resetForm({});
						if (
							location.pathname === "/recipe-suggestions/ingredients" ||
							location.pathname === "/recipe-designer/ingredients"
						) {
							history.push(location.pathname.replace("ingredients", "recipe"));
						}
					} else {
						toast(<Toast messageKey={"loginError"} />);
						resetForm({});
					}
				} else {
					console.log("LOGIN ERROR");
					resetForm({
						values: {
							email: values.username,
							password: values.password,
							grant_type: "password",
						},
						status: {
							error: "Invalid username / password",
						},
					});
					console.log(formik);
				}
			} catch (e) {
				console.warn(e);
				toast(<Toast messageKey={"loginError"} />);
			}
		},
	});

	const date = new Date();
	date.setFullYear(date.getFullYear() - 18);

	return (
		<div className="container-lg" data-testid="login-modal">
			<div className="row">
				<form onSubmit={formik.handleSubmit} onMouseUp={handleMouseUpPassword}>
					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ">
								{" "}
								<h1>
									Login or signup so you can save rate and share your recipes
								</h1>
							</div>
						</div>
					)}

					{text && text.form && (
						<div className="col-12  col-lg-6 mx-auto text-intro">
							<div className="mb-4 ">
								{" "}
								<h2>Login</h2>
							</div>
						</div>
					)}

					<div
						className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group"
						data-testid="login-email"
					>
						<StyledLabel
							control={
								<StyledTextField
									id="email"
									name="email"
									value={formik.values.email || ""}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your email here...."
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Email address</Label>}
							labelPlacement="top"
						/>
					</div>
					<div
						className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group"
						data-testid="login-password"
					>
						<StyledLabel
							control={
								<StyledTextField
									id="password"
									name="password"
									value={formik.values.password || ""}
									onChange={formik.handleChange}
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									helperText={formik.touched.password && formik.errors.password}
									fullWidth // eslint-disable-line
									style={{ margin: 8 }}
									placeholder="Type your password here...."
									margin="normal"
									type={showPassword ? "text" : "password"} // <-- This is where the magic happens
									InputProps={{
										// <-- This is where the toggle button is added.
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? (
														<Visibility
															style={{ color: "#000", zIndex: "99" }}
														/>
													) : (
														<VisibilityOff
															style={{ color: "#000", zIndex: "99" }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									autoComplete="current-password"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							}
							label={<Label>Password</Label>}
							labelPlacement="top"
						/>
					</div>
					{formik.status && formik.status.error && (
						<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group error-container">
							<ErrorContainer>
								<p>{formik.status.error}</p>
							</ErrorContainer>
						</div>
					)}

					<div className="col-12 mx-auto col-lg-6 d-flex justify-content-end form-group pe-2 mt-2">
						<ForgottenPassword
							onClick={() => {
								dispatch(toggleShowPasswordReset());
							}}
						>
							Forgotten Password
						</ForgottenPassword>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center form-group mb-4 ">
						<button type="submit" className="btn btn-secondary w-100">
							Login
						</button>
					</div>

					<div className="col-12  col-lg-6 mx-auto d-flex justify-content-center flex-column align-items-center form-group mb-5">
						<p className="text-center w-100 mb-4 ">
							Need an account?
							<SignUpSpan
								bold
								onClick={() => dispatch(toggleShowSignUpForm(true))}
							>
								SIGN UP
							</SignUpSpan>
						</p>
						{showSkip && (
							<p className="text-center w-100 ">
								<SignUpSpan
									onClick={() => {
										history.push("recipe");
										dispatch(toggleShowSignUpForm(false));
									}}
								>
									Skip This
								</SignUpSpan>
							</p>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
