import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import styled from "styled-components";
import {
	selectBuiltRecipeState,
	selectIsSaveRecipe,
} from "../../redux/builtRecipe";
import { useSelector } from "react-redux";
import { fetchPDF } from "../../redux/builtRecipe/recipeAPI";
import printJS from "print-js";
import SocialShare from "./SocialShare";
import Modal from "../common/Modal";
import SaveRecipeButton from "../saveRecipe/SaveRecipeButton";
import RateAndReview from "../saveRecipe/RateAndReview";

const Container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 3rem;
	h6 {
		font-size: 1rem;
		color: #40102a;
		font-weight: 600;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;
const Button = styled.div`
	background: #d55d35;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	margin: 1rem;
	display: grid;
	place-items: center;
	cursor: pointer;
`;
const Loader = styled.div`
	border-radius: 50%;
	height: 40px;
	width: 40px;
	margin: 1rem;
	display: grid;
	place-items: center;
	cursor: disabled;
`;

const ShareRecipe = () => {
	const state = useSelector(selectBuiltRecipeState);
	const isSaveRecipe = useSelector(selectIsSaveRecipe);
	const [isDownloadLoading, setIsDownloadLoading] = useState(false);
	const [isPrintLoading, setIsPrintLoading] = useState(false);
	const [isEmailLoading, setIsEmailLoading] = useState(false); // eslint-disable-line
	const [isModalOpen, setModalOpen] = useState(false);

	const getState = (state) => ({
		style:
			(state.builtRecipe.selectedStyle && state.builtRecipe.selectedStyle.id) ||
			"",
		flavour:
			(state.builtRecipe.selectedFlavour &&
				state.builtRecipe.selectedFlavour.id) ||
			"",
		aroma:
			(state.builtRecipe.selectedAroma && state.builtRecipe.selectedAroma.id) ||
			"",
		colour:
			(state.builtRecipe.selectedColour &&
				state.builtRecipe.selectedColour.id) ||
			"",
		abv:
			(state.builtRecipe.selectedABV && state.builtRecipe.selectedABV.id) || "",
		yeast:
			(state.builtRecipe.selectedYeast &&
				state.builtRecipe.selectedYeast.length > 0 &&
				state.builtRecipe.selectedYeast.map(({ id, value }) => ({
					id,
					value,
				}))) ||
			[],
		hop:
			(state.builtRecipe.selectedHop &&
				state.builtRecipe.selectedHop.length > 0 &&
				state.builtRecipe.selectedHop.map(({ id, value }) => ({
					id,
					value,
				}))) ||
			[],
		extract:
			(state.builtRecipe.selectedExtract &&
				state.builtRecipe.selectedExtract.length > 0 &&
				state.builtRecipe.selectedExtract.map(({ id, value }) => ({
					id,
					value,
				}))) ||
			[],
		volume:
			(state.builtRecipe.selectedVolume &&
				state.builtRecipe.selectedVolume.label) ||
			"",
	});
	const onPrintHandler = async () => {
		console.log("Printing PDF");
		setIsPrintLoading(true);
		const response = await fetchPDF(getState(state));

		const url = window.URL.createObjectURL(
			new Blob([response], { type: "application/pdf" })
		);
		printJS({
			printable: url,
			type: "pdf",
			showModal: true,
		});
		setIsPrintLoading(false);
	};

	const onDownloadHandler = async () => {
		console.log("Fetch PDF");
		setIsDownloadLoading(true);
		const response = await fetchPDF(getState(state));

		const url = window.URL.createObjectURL(
			new Blob([response], { type: "application/pdf" })
		);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute(
			"download",
			`My-${state.builtRecipe.selectedStyle.label}-recipe.pdf`
		);
		document.body.appendChild(link);
		link.click();
		setIsDownloadLoading(false);
	};

	return (
		<Container>
			{isSaveRecipe && <RateAndReview />}
			{!isSaveRecipe && <SaveRecipeButton />}
			<h6>Download, Share or Print your recipe</h6>
			<ButtonContainer>
				{!isDownloadLoading && (
					<Button>
						{" "}
						<GetAppOutlinedIcon onClick={onDownloadHandler} htmlColor="#fff" />
					</Button>
				)}
				{isDownloadLoading && (
					<Loader>
						<CircularProgress style={{ color: "#d55d35" }} />
					</Loader>
				)}

				{!isEmailLoading && (
					<Button>
						{" "}
						<ShareOutlinedIcon
							onClick={() => setModalOpen(true)}
							htmlColor="#fff"
						/>
					</Button>
				)}
				{isEmailLoading && (
					<Loader>
						<CircularProgress style={{ color: "#d55d35" }} />
					</Loader>
				)}

				{!isPrintLoading && (
					<Button>
						{" "}
						<PrintOutlinedIcon onClick={onPrintHandler} htmlColor="#fff" />{" "}
					</Button>
				)}
				{isPrintLoading && (
					<Loader>
						<CircularProgress style={{ color: "#d55d35" }} />
					</Loader>
				)}
			</ButtonContainer>
			<Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
				<SocialShare />
			</Modal>
		</Container>
	);
};

export default ShareRecipe;
