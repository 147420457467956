import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSelections } from "../redux/builtRecipe";
import {
	fetchActionTextAsync,
	fetchLandingPageContentAsync,
	selectLandingPageContent,
} from "../redux/content";
import styled from "styled-components";
import { baseUmbracoUrl } from "../app/axios";

const BackgroundCard = styled.div`
	background-image: url("${({ img }) => img}");
	background-size: cover;
	background-repeat: no-repeat;
	height: 50vh;
	background-position: ${({ position }) => (position ? position : "initial")};

	.text-right {
		text-align: right;
	}
`;

const GradientLayerDark = styled.div`
	display: flex;
	padding: 3rem;
	height: 100%;
	width: 100%;
	background: linear-gradient(
			90deg,
			transparent 0%,
			rgba(0, 0, 0, 0.2) 50%,
			rgba(0, 0, 0, 0.8) 100%
		)
		0% 0% no-repeat padding-box padding-box transparent;
	border-radius: 20px;
`;

const GradientLayerLight = styled.div`
	display: flex;
	padding: 3rem;
	height: 100%;
	width: 100%;
	background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.8) 0%,
			rgba(0, 0, 0, 0.3) 50%,
			transparent 100%
		)
		0% 0% no-repeat padding-box padding-box transparent;
	border-radius: 20px;
`;
const LandingPage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const {
		title,
		imageLeft,
		headerLeft,
		textLeft,
		imageRight,
		headerRight,
		textRight,
	} = useSelector(selectLandingPageContent) || {};

	useEffect(() => {
		dispatch(fetchLandingPageContentAsync());
		dispatch(fetchActionTextAsync());
		if (location.pathname === "/") dispatch(resetSelections());
	}, [dispatch, location.pathname]);
	return (
		<main className="container-xxl">
			<div className="row mb-2">
				<div className="col-md-12 text-center text-intro py-3">
					<h1>{title}</h1>
				</div>
			</div>

			<div className="row align-items-md-stretch">
				<div className="col-md-6 mb-4">
					<Link to="/recipe-designer/style">
						<BackgroundCard
							className="bg-white shadow selection-card"
							img={baseUmbracoUrl + imageLeft}
						>
							<GradientLayerDark>
								<div className="row align-items-center w-100">
									<div className="col-6"></div>
									<div className="col-6 text-right">
										<h2 className="text-white">{headerLeft}</h2>
										<p className="text-white">{textLeft}</p>
									</div>
								</div>
							</GradientLayerDark>
						</BackgroundCard>
					</Link>
				</div>
				<div className="col-md-6 mb-4">
					<Link to="/recipe-suggestions/style">
						<BackgroundCard
							position="right"
							className="  bg-white shadow selection-card"
							img={baseUmbracoUrl + imageRight}
						>
							<GradientLayerLight>
								<div className="row align-items-center">
									<div className="col-6">
										<h2 className="text-white">{headerRight}</h2>
										<p className="text-white">{textRight}</p>
									</div>
									<div className="col-6"></div>
								</div>
							</GradientLayerLight>
						</BackgroundCard>
					</Link>
				</div>
			</div>
		</main>
	);
};

export default LandingPage;
