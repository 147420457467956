import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFlavoursAsync } from "../../redux/flavour";
import { selectFilteredFlavours } from "../../redux/style";
import { selectFlavour, builtRecipeSelectors } from "../../redux/builtRecipe";
import ActionText from "../../components/actionText/ActionText";
import Cards from "../../components/cards/Cards";
import BottomNav from "../../components/bottomNav/BottomNav";
import BeerImage from "../../components/beerImage/BeerImage";
import { useLocation } from "react-router";

const Flavour = () => {
	const flavours = useSelector(selectFilteredFlavours);
	const selectedFlavour = useSelector(builtRecipeSelectors.flavour);
	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		if (
			location.state &&
			location.state.prevPath &&
			location.state.prevPath.includes("style")
		) {
			window.scrollTo(0, 0);
		}
		dispatch(fetchFlavoursAsync());
	}, [dispatch, location.state]);
	return (
		<>
			<main className="container-xxl">
				<ActionText page="flavour" mobile />
				<div className="row">
					<BeerImage />
					<Cards
						page="flavour"
						selection={selectedFlavour}
						list={flavours}
						onClickHandler={(type) => dispatch(selectFlavour(type))}
						bottomNav
						nextStep={"/recipe-designer/aroma"}
					/>
				</div>{" "}
				<BottomNav mobile />
			</main>
		</>
	);
};

export default Flavour;
