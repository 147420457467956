import React from "react";
import { StyledLabel, Label, StyledTextField } from "../loginForm/LoginInputs";
import { StyledRating } from "../loginForm/LoginInputs";
import { useFormik } from "formik";
import { selectInstructions, updateRatingAsync } from "../../redux/builtRecipe";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../toast/Toast";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	& button {
		width: 50%;
	}

	@media (max-width: 768px) {
		flex-direction: column;

		& button {
			width: 100%;
			margin-bottom: 2rem;
		}
	}
`;

const RecipeButton = styled.button`
	&&&& {
		background: #fff;
		border-color: #422337 !important;
		border-radius: 30px !important;
		color: #422337 !important;
	}
`;

const validationSchema = yup.object({
	name: yup.string("Enter a recipe name").required("Recipe Name is required"),
});

const RateAndReview = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const viewRecipes = () => history.push("/saved-recipes");
	let { recipeName, recipeRating, recipeReview } =
		useSelector(selectInstructions);

	const formik = useFormik({
		initialValues: {
			name: recipeName,
			rating: recipeRating,
			review: recipeReview,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const response = await dispatch(updateRatingAsync(values));
			console.log(response.payload);
			if (response.payload) {
				toast(<Toast messageKey="reviewSuccess" />);
			}
		},
	});

	return (
		<div className="row">
			<form onSubmit={formik.handleSubmit}>
				<div className="col-12   mx-auto d-flex justify-content-center form-group">
					<StyledLabel
						control={
							<StyledTextField
								id="name"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
								fullWidth // eslint-disable-line
								style={{ margin: 8 }}
								placeholder="Type your name here...."
								margin="normal"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
						label={<Label>Your beer name</Label>}
						labelPlacement="top"
					/>
				</div>
				<div className="col-12  ms-3 mx-auto d-flex justify-content-center form-group">
					<p>
						Remember to save your updated details by clicking the update button.
					</p>
				</div>
				<div className="col-12   mx-auto d-flex justify-content-center form-group">
					<StyledLabel
						control={
							<StyledRating
								id="rating"
								name="rating"
								value={formik.values.rating}
								onChange={(event, val) => {
									formik.setFieldValue("rating", val);
								}}
								error={formik.touched.rating && Boolean(formik.errors.rating)}
								helperText={formik.touched.rating && formik.errors.rating}
								fullWidth // eslint-disable-line
								style={{ margin: 8 }}
								margin="normal"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
						label={<Label>Rate this beer</Label>}
						labelPlacement="top"
					/>
				</div>
				<div className="col-12   mx-auto d-flex justify-content-center form-group">
					<StyledLabel
						control={
							<StyledTextField
								id="review"
								name="review"
								value={formik.values.review}
								onChange={formik.handleChange}
								error={formik.touched.review && Boolean(formik.errors.review)}
								helperText={formik.touched.review && formik.errors.review}
								fullWidth // eslint-disable-line
								style={{ margin: 8 }}
								placeholder="Type your review here...."
								margin="normal"
								variant="outlined"
								multiline
								minRows={5}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
						label={<Label>Review your beer</Label>}
						labelPlacement="top"
					/>
				</div>

				<ButtonContainer className=" form-group mb-4 mt-5">
					<button type="submit" className="btn btn-secondary me-4">
						Update details
					</button>

					<RecipeButton className="btn  " onClick={viewRecipes}>
						View my recipes
					</RecipeButton>
				</ButtonContainer>
			</form>
		</div>
	);
};

export default RateAndReview;
