import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { builtRecipeSelectors } from "../redux/builtRecipe";
import Cards from "../components/cards/Cards";

const Confirmation = () => {
	const selectedABV = useSelector(builtRecipeSelectors.abv);
	const selectedAroma = useSelector(builtRecipeSelectors.aroma);
	const selectedColour = useSelector(builtRecipeSelectors.colour);
	const selectedFlavour = useSelector(builtRecipeSelectors.flavour);
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const builtRecipe = [
		selectedFlavour,
		selectedColour,
		selectedAroma,
		selectedABV,
	];
	const links = ["flavour", "colour", "aroma", "abv"];
	const linksAddedToRecipes = builtRecipe.map((item, i) => ({
		...item,
		to: links[i],
	}));
	return (
		<main className="container-xxl">
			<div className="row mb-2">
				<div className="col-md-12 text-center text-intro py-3">
					<h1>
						Your <strong>brew</strong> summary
					</h1>
				</div>
				<div className="col-12 text-center d-none d-xl-block">
					<p className="feature-text">
						Here are the <strong>Flavour, Colour, Aroma</strong> and{" "}
						<strong>ABV</strong> options you have selected, to edit, click
						<span className="edit-icon">
							<i className="fas fa-pencil-alt"></i>
						</span>
					</p>
				</div>
			</div>

			<div className="row justify-content-center py-4 mb-5">
				<div className="col-6 col-sm-4 col-md-4">
					<img
						className="img-fluid w-100"
						src={selectedStyle.img}
						alt="glass of beer"
					/>
				</div>
				<div className="col-4 offset-2 d-none d-xl-block">
					<Cards isConfirmationPage list={linksAddedToRecipes} />
				</div>

				<div className="col-12 col-xl-4 d-none d-xl-block  mt-3">
					<Link to="ingredients" className="btn btn-secondary w-100">
						Confirm & Choose my Ingredients
					</Link>
				</div>
			</div>

			<div className="row justify-content-center py-4 d-block d-xl-none">
				<div className="col-12 text-center">
					<p className="feature-text">
						Here are the <strong>Flavour, Colour, Aroma</strong> and{" "}
						<strong>ABV</strong> options you have selected, to edit, click
						<span className="edit-icon">
							<i className="fas fa-pencil-alt"></i>
						</span>
					</p>
				</div>
			</div>

			<Cards mobile isConfirmationPage list={linksAddedToRecipes} />

			<div className="row justify-content-center py-4 mt-5 d-block d-xl-none">
				<div className="col-12 col-xl-4">
					<Link to="ingredients" className="btn btn-secondary w-100">
						Confirm & Choose my Ingredients
					</Link>
				</div>
			</div>
		</main>
	);
};

export default Confirmation;
