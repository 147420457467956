import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedSuggestion } from "../../redux/builtRecipe";

const DrinkDescription = () => {
	const { description } = useSelector(selectSelectedSuggestion);
	return (
		<div className="col-10 text-center offset-1">
			<p>{description}</p>
		</div>
	);
};

export default DrinkDescription;
