import axios from "axios";

import { toast } from "react-toastify";
import { fetchSavedRecipesAsync, setIsSignedIn } from "../redux/user";
import { setRecipeId } from "../redux/builtRecipe";
import Toast from "../components/toast/Toast";

export const baseUmbracoUrl =
	window.location.hostname === "homebrew.muntons.com"
		? "https://api-homebrew.muntons.com"
		: "https://muntons-api.chickdigital.co.uk";

export const baseUrl = "/api/contents/";

export const contactBaseUrl = "/api/contact/";

export const recipeBaseUrl = "/api/instructions";

export const pdfBaseUrk = "/api/pdf";

const authUrls = [
	"/api/instructions/saved/all",
	"/api/instructions/save",
	"/api/instructions/review",
	"/api/instructions/delete",
];

const messageKeys = {
	"/api/instructions/delete": "deleteRecipeError",
	"/api/instructions/review": "reviewError",
	"/api/instructions/save": "recipeSavedError",
	"/api/instructions/saved/all": "getSavedRecipesError",
};

const messageSuccessKeys = {
	"/api/instructions/review": "reviewSuccess",
	"/api/instructions/save": "recipeSavedSuccess",
};

let store;

export const injectStore = (_store) => {
	store = _store;
};

const logout = () => {
	console.log("New Token Failed - Logging out");
	// store.dispatch(setIsSignedIn());
	// window.location.pathname = "/";
};

axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		const token = localStorage.getItem("access_token");
		console.log(
			"Use Auth",
			authUrls.some((path) => config.url.includes(path))
		);
		const { pathname } = new URL(config.url);

		if (
			token &&
			token !== "undefined" &&
			authUrls.some((path) => pathname === path)
		) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

let previousRequest = axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (
			error.response.status === 401 &&
			!error.response.config.url.includes("/api/oauth/token")
		) {
			console.log("Getting New Token");
			console.log(error.config);
			var formBody = [];
			const refreshToken = localStorage.getItem("refresh_token");
			if (refreshToken) {
				console.log("Refresh Token Old: ", refreshToken);
				const values = {
					grant_type: "refresh_token",
					refresh_token: refreshToken,
				};
				for (var property in values) {
					var encodedKey = encodeURIComponent(property);
					var encodedValue = encodeURIComponent(values[property]);
					formBody.push(encodedKey + "=" + encodedValue);
				}
				formBody = formBody.join("&");

				const newToken = await axios({
					method: "POST",
					url: baseUmbracoUrl + "/api/oauth/token",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
					},
					data: formBody,
				});

				console.log(!!newToken);
				console.log("test");
				if (newToken) {
					console.log("22 test 2");

					console.log("33test 3");
					if (newToken?.data?.refresh_token && newToken?.data?.access_token) {
						console.log("New Token Returned");
						localStorage.setItem("access_token", newToken.data.access_token);
						localStorage.setItem("refresh_token", newToken.data.refresh_token);
						const originalRequestConfig = error.config;

						delete originalRequestConfig.headers["Authorization"];

						originalRequestConfig.headers[
							"Authorization"
						] = `Bearer ${newToken.data.access_token}`;
						previousRequest = originalRequestConfig;
						const { pathname } = new URL(originalRequestConfig.url);
						if (pathname === "/api/instructions/saved/all") {
							console.log("Dispatching Recipes");
							await store.dispatch(fetchSavedRecipesAsync());
						} else {
							const retriedRequest = await axios(originalRequestConfig);
							if (retriedRequest.status !== 200) {
								toast(<Toast messageKey={messageKeys[pathname]} />);
							} else if (
								retriedRequest.status === 200 &&
								(pathname === "/api/instructions/review" ||
									pathname === "/api/instructions/save")
							) {
								toast(<Toast messageKey={messageSuccessKeys[pathname]} />);

								if (pathname === "/api/instructions/save") {
									console.log("Setting Recipe ID: ", retriedRequest.data);
									store.dispatch(setRecipeId(retriedRequest.data));
								}
							}
						}
					} else {
						logout();
					}
				} else {
					logout();
				}
			} else {
				logout();
			}
		}

		if (error.response.status === 500) {
			const { pathname } = new URL(error.response.config.url);

			toast(<Toast messageKey={messageKeys[pathname] || "error"} />);
		}

		return error;
	}
);

export const request = (url) =>
	axios.get(baseUmbracoUrl + baseUrl + url).then((res) => res.data);

export const requestSavedRecipe = (id) =>
	axios
		.get(baseUmbracoUrl + recipeBaseUrl + `/saved?recipeId=${id}`)
		.then((res) => res.data);

export const formPost = async (url, body) => {
	axios({
		method: "POST",
		url: baseUmbracoUrl + contactBaseUrl + url,
		data: body,
	}).then((res) => res.data);
};

export const signUpForm = async (url, body) =>
	axios({
		method: "POST",
		url: baseUmbracoUrl + contactBaseUrl + url,
		data: body,
	}).then((res) => {
		console.log(res);
		return res;
	});

export const recipePost = async (body) =>
	axios({
		method: "POST",
		url: baseUmbracoUrl + recipeBaseUrl,
		data: body,
	}).then((res) => res.data);

export const pdfPost = async (body) =>
	axios({
		method: "POST",
		url: baseUmbracoUrl + pdfBaseUrk,
		responseType: "blob",
		data: body,
	}).then((res) => res.data);

export const pdfPostShare = async (body) =>
	axios({
		method: "POST",
		url: baseUmbracoUrl + pdfBaseUrk + "/share",

		data: body,
	}).then((res) => res.data);

export const formLoginPost = async (values) => {
	var formBody = [];
	for (var property in values) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(values[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");

	return axios({
		method: "POST",
		url: baseUmbracoUrl + "/api/oauth/token",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
		},
		data: formBody,
	}).then((res) => res);
};

export const getSavedRecipes = async (body) => {
	return axios({
		method: "GET",
		url: baseUmbracoUrl + recipeBaseUrl + "/saved/all",
		data: body,
	}).then((res) => res.data);
};

export const deleteSavedRecipe = async (id) => {
	return axios({
		method: "POST",
		url: baseUmbracoUrl + recipeBaseUrl + `/delete?recipeId=${id}`,
	}).then((res) => res);
};

export const reviewAndRatingPost = async (body) => {
	const token = localStorage.getItem("access_token");

	const config = {
		Authorization: `Bearer ${token}`,
	};
	return axios({
		method: "POST",
		url: baseUmbracoUrl + recipeBaseUrl + "/review",
		data: body,
		headers: config,
	}).then((res) => res.data);
};

export const saveRecipe = async (body) => {
	return axios({
		method: "POST",
		url: baseUmbracoUrl + recipeBaseUrl + "/save",
		data: body,
	}).then((res) => res.data);
};
