import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchColours } from "./colourAPI";

const initialState = {
	colours: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchColoursAsync = createAsyncThunk(
	"colour/fetchColours",
	async () => {
		const response = await fetchColours();
		// The value we return becomes the `fulfilled` action payload
		return response;
	}
);

export const colourSlice = createSlice({
	name: "colour",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(fetchColoursAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchColoursAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.colours = action.payload;
			});
	},
});

export const selectColours = (state) => state.colour.colours;

export default colourSlice.reducer;
