import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Menu from "../menu";
import Logo from "../../assets/img/WEB_LOGO_HEADER.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { ExitToAppOutlined } from "@material-ui/icons";
import SportsBarOutlinedIcon from "@mui/icons-material/SportsBarOutlined";

import { toggleLoginForm } from "../../redux/content";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSignedIn, setIsSignedIn } from "../../redux/user";
import { toast } from "react-toastify";
import Toast from "../toast/Toast";

const StyledHeaderContainer = styled.div`
	position: fixed;
	top: 0;
	left: 50%;
	width: 100%;
	transform: translate(-50%, 0);
	z-index: 1001;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 20%
	);

	button {
		border: none;
		background: transparent;
	}
`;

export const Header = () => {
	const isSignedIn = useSelector(selectIsSignedIn);
	const dispatch = useDispatch();
	const onLoginButtonClick = () => {
		dispatch(toggleLoginForm());
	};

	const onLogoutButtonClick = () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		dispatch(setIsSignedIn());
		toast(<Toast messageKey="logoutSuccess" />);
	};
	console.log("Signed In", isSignedIn);
	return (
		<StyledHeaderContainer className=" header-container">
			<header className="py-3 container">
				<div className="row flex-nowrap justify-content-between align-items-center">
					<div className="col-4">
						<Menu />
					</div>
					<div className="col-4 text-center">
						<Link className="header-logo text-dark nav-items-position" to="/">
							<img src={Logo} alt="muntons logo " />
						</Link>
					</div>
					<div className="col-4 d-flex justify-content-end align-items-center">
						{isSignedIn ? (
							<>
								<div className="me-3">
									<Link to="/saved-recipes">
										<SportsBarOutlinedIcon
											style={{ cursor: "pointer" }}
											data-testid="account-button"
										/>
									</Link>
								</div>
								<ExitToAppOutlined
									onClick={onLogoutButtonClick}
									style={{ cursor: "pointer" }}
									data-testid="account-button"
								/>
							</>
						) : (
							<div className=" pt-1 me-3 d-flex flex-column align-items-center">
								<AccountCircleOutlinedIcon
									onClick={onLoginButtonClick}
									style={{ cursor: "pointer" }}
									data-testid="account-button"
								/>
								<p>Signup/login</p>
							</div>
						)}
						{/* <Link className="nav-items-position" to="/">
								<img src={ReloadIcon} alt="restart button" />
							</Link> */}
					</div>
				</div>
			</header>
		</StyledHeaderContainer>
	);
};
