import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchSuggestionsAsync,
	selectSuggestions,
} from "../../redux/suggestions";
import {
	selectStyle,
	builtRecipeSelectors,
	selectFlavour,
	selectABV,
	selectAroma,
	selectColour,
	selectExtract,
	selectHop,
	selectYeast,
	fetchRecipeAsync,
	resetSelections,
	selectVolume,
	selectSuggestion,
} from "../../redux/builtRecipe";
import { fetchStylesAsync, selectStyles } from "../../redux/style";
import { fetchSuggestedRecipe } from "../../redux/suggestions/suggestionsAPI";
import { baseUmbracoUrl } from "../../app/axios";
import ActionText from "../../components/actionText/ActionText";
import FullPageCards from "../../components/cards/FullPageCards";

const Style = () => {
	const styles = useSelector(selectSuggestions);
	const selectedStyle = useSelector(builtRecipeSelectors.style);
	const drinkStyles = useSelector(selectStyles);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(resetSelections());
		dispatch(fetchSuggestionsAsync());
		dispatch(fetchStylesAsync());
		window.scrollTo(0, 0);
	}, [dispatch]);

	const onClickHandler = async (type) => {
		const ingredients = await fetchSuggestedRecipe(type.id);

		const arr = ["colour", "aroma", "baseFlavour", "yeast", "hop", "extract"];
		arr.forEach((prop) => {
			if (ingredients[prop]) {
				ingredients[prop].img =
					baseUmbracoUrl +
					((ingredients[prop].images && ingredients[prop].images[0]) || "");
			}
		});

		if (ingredients.colour) {
			ingredients.colour.isBackgroundImage = true;
		}

		if (type.id) {
			const style = drinkStyles.find((style) => style.id === type.id);
			type.numberOfSachets = style.numberOfSachets;
		}

		dispatch(selectFlavour(ingredients.baseFlavour || {}));
		dispatch(selectABV(ingredients.abv || {}));
		dispatch(selectAroma(ingredients.aroma || {}));
		dispatch(selectColour(ingredients.colour || {}));
		dispatch(selectExtract(ingredients.extract || {}));
		dispatch(selectHop(ingredients.hop || {}));
		dispatch(selectStyle(type || {}));
		dispatch(selectYeast(ingredients.yeast || {}));
		dispatch(selectVolume({ value: 40, label: 40 }));

		dispatch(fetchRecipeAsync());

		dispatch(selectSuggestion(type));
	};

	return (
		<main className="container-xxl">
			<ActionText page="style" showBoth />
			<FullPageCards
				selection={selectedStyle}
				list={styles}
				onClickHandler={(type) => onClickHandler(type)}
				nextStep={"/recipe-suggestions/recipe"}
			/>
		</main>
	);
};

export default Style;
