import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectColour, builtRecipeSelectors } from "../../redux/builtRecipe";
import { selectFilteredColours } from "../../redux/style";
import ActionText from "../../components/actionText/ActionText";
import Cards from "../../components/cards/Cards";
import BottomNav from "../../components/bottomNav/BottomNav";
import BeerImage from "../../components/beerImage/BeerImage";

const Colour = () => {
	const colours = useSelector(selectFilteredColours);

	const selectedColour = useSelector(builtRecipeSelectors.colour);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(fetchColoursAsync());
	// }, [dispatch]);
	return (
		<>
			<main className="container-xxl">
				<ActionText page="colour" mobile />
				<div className="row">
					<BeerImage />
					<Cards
						page="colour"
						selection={selectedColour}
						list={colours}
						onClickHandler={(type) => dispatch(selectColour(type))}
						bottomNav
						nextStep={"/recipe-designer/abv"}
					/>
				</div>
				<BottomNav mobile />
			</main>
		</>
	);
};

export default Colour;
