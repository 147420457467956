import {
	recipePost,
	pdfPost,
	pdfPostShare,
	requestSavedRecipe,
	reviewAndRatingPost,
} from "../../app/axios";

export const fetchRecipe = async (recipe) => {
	let data = await recipePost(recipe);

	return data;
};

export const fetchPDF = async (recipe) => {
	let data = await pdfPost(recipe);

	return data;
};

export const fetchPDFShare = async (recipe) => {
	let data = await pdfPostShare(recipe);

	return data;
};

export const fetchSavedRecipe = async (id) => {
	let data = await requestSavedRecipe(id);
	//data = data.items.filter((d) => d);
	console.log(data);
	return data;
};

export const postReviewAndRating = async (rating) => {
	let data = await reviewAndRatingPost(rating);

	return data;
};
